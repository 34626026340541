<template>
  <div class="BHeader">
    <div class="BHeader-left">
      <slot />
    </div>
    <div class="BHeader-right">
      <slot name="right" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'BHeader'
}
</script>

<style scoped lang="less">
  .BHeader{
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
</style>
