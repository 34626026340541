<template>
  <div class="LayoutIndex">
    <RouterView />
  </div>
</template>

<script>
export default {
  name: 'LayoutIndex'
}
</script>

<style scoped lang="less">
  .LayoutIndex {
    width: 100%;
    height: 100%;
  }
</style>
