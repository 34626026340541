import LayoutMain from '@/layouts/LayoutMain'
import LayoutIndex from '@/layouts/LayoutIndex'
import {
  APP_ROUTES,
  LOG_ROUTES
} from '@/router/asyncRoutes/netboom-android/routesData'

export default [
  {
    path: 'netboomAndroid',
    alias: '/',
    component: LayoutIndex,
    redirect: '/netboomAndroid/dashboard',
    meta: {
      title: 'NetboomAndorid',
      isLayout: true
    },
    children: [
      {
        path: '/netboomAndroid/dashboard',
        component: LayoutMain,
        children: [
          {
            path: '/netboomAndroid/dashboard',
            name: 'Dashboard',
            meta: {
              title: 'Dashboard',
              icon: 'dashboard'
            },
            component: () => import('@/views/TODO')
          }
        ]
      },
      APP_ROUTES,
      {
        path: 'trade-management',
        redirect: 'OrderList',
        name: 'tradeManagement',
        alwaysShow: true,
        meta: {
          title: 'TradeManagement',
          icon: 'rmb'
        },
        component: LayoutMain,
        children: [
          {
            path: 'OrderList',
            component: () => import('@/views/trade-management/OrderList'),
            name: 'OrderList',
            meta: {
              title: 'OrderList'
            }
          }
        ]
      },
      {
        path: 'UgcManagement',
        component: LayoutMain,
        name: 'UgcManagement',
        alwaysShow: true,
        meta: {
          title: 'UGC管理',
          icon: 'play-circle'
        },
        children: [
          {
            path: 'ugc-examine-and-verify',
            name: 'UgcExamineAndVerify',
            meta: {
              title: '用户UGC视频审核',
              icon: 'play-circle'
            },
            component: () => import('@/views/ugc-video/index')
          }
        ]
      },
      LOG_ROUTES,
      {
        path: 'score',
        component: LayoutMain,
        alwaysShow: true,
        meta: {
          title: '评分',
          icon: 'pie-chart'
        },
        children: [
          {
            path: 'overview',
            name: 'ScoreOverview',
            meta: {
              title: '评分',
              icon: 'pie-chart'
            },
            component: () => import('@/views/score/overview')
          },
          {
            path: 'log',
            name: 'ScoreLog2',
            hidden: true,
            meta: {
              title: '评分日志'
            },
            component: () => import('@/views/score/LogOfScore')
          }
        ]
      },
      {
        path: 'RechargeSystem',
        component: LayoutMain,
        name: 'RechargeSystem',
        alwaysShow: true,
        meta: {
          title: 'RechargeSystem',
          icon: 'cc-visa'
        },
        children: [
          {
            path: 'SyncPrice',
            name: 'SyncPrice',
            meta: {
              title: 'SyncPrice'
            },
            component: () => import('@/views/recharge-system/SyncPrice')
          },
          {
            path: 'recharge',
            name: 'AccountRecharge',
            meta: {
              title: 'AccountRecharge'
            },
            component: () => import('@/views/operational-tool/Recharge')
          },
          {
            path: 'GoodsItemCreate',
            name: 'GoodsItemCreate',
            hidden: true,
            meta: {
              title: '创建新商品[支付]'
            },
            component: () => import('@/views/recharge-system/GoodsItemCreateOrEdit')
          },
          {
            path: 'GoodsItemEdit',
            name: 'GoodsItemEdit',
            hidden: true,
            meta: {
              title: '编辑商品[支付]'
            },
            component: () => import('@/views/recharge-system/GoodsItemCreateOrEdit')
          }
        ]
      },
      {
        path: 'TreasureBox',
        component: LayoutMain,
        meta: {
          menu: true,
          title: '宝箱活动',
          icon: 'lock'
        },
        children: [
          {
            path: 'dashboard',
            component: () => import('@/views/TreasureBox/Dashboard'),
            name: 'TreasureBoxDashboard',
            meta: {
              menu: true,
              title: '活动管理'
            }
          },
          {
            path: 'management',
            component: () => import('@/views/TreasureBox/Management'),
            name: 'TreasureBoxManagement',
            meta: {
              menu: true,
              title: '管理活动'
            }
          },
          {
            path: 'rule-edit',
            component: () => import('@/views/TreasureBox/RuleEdit'),
            name: 'TreasureBoxRuleEdit',
            meta: {
              menu: true,
              title: '活动规则管理'
            }
          }
        ]
      },
      {
        path: 'festival-activities',
        component: LayoutMain,
        alwaysShow: true,
        meta: {
          menu: true,
          title: '节日活动',
          icon: 'lock'
        },
        children: [
          {
            path: '',
            component: () => import('@/views/festival-activities/index.vue'),
            name: 'FestivalActivities',
            meta: {
              menu: true,
              title: '节日活动'
            }
          }
        ]
      },
      {
        path: 'advertising-management',
        component: LayoutMain,
        alwaysShow: true,
        meta: {
          title: '广告管理',
          icon: 'play-circle'
        },
        children: [
          {
            path: '',
            component: () => import('@/views/advertising-management/index.vue'),
            name: 'AdvertisingManagement',
            meta: {
              menu: true,
              title: '广告管理'
            }
          },
          {
            path: 'add-advertising',
            component: () => import('@/views/advertising-management/addAdv.vue'),
            name: 'AddAdvertising',
            meta: {
              title: '新增广告'
            }
          },
          {
            path: 'edit-advertising',
            component: () => import('@/views/advertising-management/addAdv.vue'),
            name: 'EditAdvertising',
            hidden: true,
            meta: {
              title: '编辑广告'
            }
          }
        ]
      }
    ]
  }
]
