const Mock = require('mockjs')
const Random = Mock.Random
module.exports = [
  {
    url: '/queryModuleList',
    type: 'post',
    response: () => {
      return {
        code: 200,
        data: {
          total: 34,
          'list|10': [
            {
              'modulesId|43535-345563': 43535,
              modulesName: () => Random.name(),
              modulesDes: () => Random.title(),
              'weight|0-10': 0,
              'adList|0-10': 0,
              state: () => Random.pick(['上架', '下架'])
            }
          ]
        }
      }
    }
  },
  {
    url: '/queryAdvertisementList',
    type: 'post',
    response: () => {
      return {
        code: 200,
        data: {
          total: 345,
          'list|10': [
            {
              'AdId|43535-345563': 43535,
              AdName: () => Random.name(),
              AdPic: () => 'images/a5cefaddc75dbedd31d85fd7f563448a.jpeg',
              'weight|0-10': 0,
              tag: '234234',
              state: () => Random.pick(['上架', '下架']),
              startTime: '2020.9.12',
              endTime: '2020.10.2'
            }
          ]
        }
      }
    }
  }
]
