import LayoutMain from '@/layouts/LayoutMain'
import {
  AppCategoryManagement,
  LogManagement
} from '@/router/asyncRoutes/_modules'

export const APP_ROUTES = {
  path: 'app',
  redirect: 'CategoryList',
  name: 'APP',
  alwaysShow: true,
  meta: {
    title: 'APP',
    icon: 'apple'
  },
  component: LayoutMain,
  children: [
    ...AppCategoryManagement,
    {
      path: 'tag-manage',
      name: 'tagManage',
      meta: {
        title: '标签管理'
      },
      component: () => import('@/views/app/tag-manage/index.vue')
    }
  ]
}

export const LOG_ROUTES = {
  path: 'log-management',
  redirect: 'ErrorStatistics',
  name: 'logManagement',
  meta: {
    title: 'logManagement',
    icon: 'line-chart'
  },
  component: LayoutMain,
  children: LogManagement
}
