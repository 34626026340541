import LayoutMain from '@/layouts/LayoutMain'

export const constantRoutes = [
  {
    path: '/login',
    component: () => import('@/views/login/Login'),
    hidden: true
  },
  {
    path: '/404',
    component: LayoutMain,
    hidden: true,
    children: [
      {
        path: '',
        component: () => import('@/views/error/_404')
      }
    ]
  }
]
