import request from '@/utils/request'
import axios from 'axios'
import { GameFeatureTags } from '@/utils/selectOptions'
const CancelToken = axios.CancelToken

export function queryGameList (data) {
  return request({
    url: '/game/getGameList',
    data
  })
}
export function toggleGameStatus (data) {
  return request({
    url: '/game/updateGameEnableStatus',
    data
  })
}

export function getGameDetailInfo (data) {
  return request({
    url: '/game/webGetSingleGame',
    data
  })
}

export function getGameTagList (data) {
  return request({
    url: '/game/getGameTagList',
    data
  })
}

export function searchKeyBoardList (data) {
  return request({
    url: '/game/searchKeyBoard',
    data
  })
}

export function createNewGame (data) {
  return request({
    url: '/game/addGame',
    data
  })
}

export function updateGameInfo (data) {
  return request({
    url: '/game/updateGame',
    data
  })
}

export function searchGameList (data) {
  return request({
    url: '/game/webSearchGameList',
    data
  })
}

export function getEngineRoomInfo (data) {
  return request({
    url: '/game/getEngineRoomInfo',
    data
  })
}
// 新爬虫需求
// 游戏列表
export function getSpiderGameList (data) {
  return request({
    url: '/spider/web/info/list',
    data
  })
}

// 统计
export function getSpiderStatics (data) {
  return request({
    url: '/spider/web/statics',
    data
  })
}
// 新建爬虫任务
export function addTask (data) {
  return request({
    url: '/spider/addTask',
    data
  })
}

// 获取游戏详情
export function getGameDetail (data) {
  return request({
    url: '/spider/getInfo',
    data
  })
}

// 编辑游戏基本信息
export function editGameBasicInfo (data) {
  return request({
    url: '/spider/web/edit/basic',
    data
  })
}

// 编辑游戏支持语言信息
export function editGameLangsInfo (data) {
  return request({
    url: '/spider/web/edit/langs',
    data
  })
}

// 编辑游戏视频或者图片
export function editGamePromotionalInfo (data) {
  return request({
    url: '/spider/web/edit/promotional',
    data
  })
}

// 编辑游戏tag
export function editGameTagsInfo (data) {
  return request({
    url: '/spider/web/edit/tags',
    data
  })
}

// 获取热门Tags
export function getHotTags (data) {
  return request({
    url: '/spider/web/hot/tags',
    data
  })
}

// 编辑描述信息
export function editDocs (data) {
  return request({
    url: '/spider/web/edit/docs',
    data
  })
}

// 编辑评价
export function editAppraise (data) {
  return request({
    url: '/spider/web/edit/appraise',
    data
  })
}

// 编辑游戏名字
export function editGameName (data) {
  return request({
    url: '/spider/web/edit/gameName',
    data
  })
}

// 编辑 单个图片
export function editGameImage (data) {
  return request({
    url: '/spider/web/edit/image',
    data
  })
}

// 同步至线上
export function synchronizeSpiderData (data) {
  return request({
    url: '/game/synchronizeSpiderData',
    data
  })
}

// 获取标签列表
export function queryAllGameTagList (data) {
  return request({
    url: '/spider/web/tag/list',
    data
  })
}

// 标签分组配置
export function queryGameTagGroupList (data) {
  return request({
    url: '/spider/web/tag/group/config',
    data
  })
}

// 标签分组配置
export function deleteGameTag (data) {
  return request({
    url: '/spider/web/delete/hot/tag',
    data
  })
}

// 单个热门标签详情
export function queryGameTagdetail (data) {
  return request({
    url: '/spider/web/hot/tag/detail',
    data
  })
}

export function tagNameValidateCheck (data) {
  return request({
    url: '/spider/web/query/tag/name',
    data
  })
}

export function createNewTag (data) {
  return request({
    url: '/spider/web/add/hot/tag',
    data
  })
}

export function updateGameTag (data) {
  return request({
    url: '/spider/web/edit/hot/tag',
    data
  })
}
// steam评论列表
export function steamCommentList (data) {
  return request({
    url: '/game/getGameCommitList',
    data
  })
}
// steam评论修改状态
export function steamUpdateStatus (data) {
  return request({
    url: '/game/updateStatus',
    data
  })
}
// steam艺术品列表
export function steamArtistList (data) {
  return request({
    url: '/game/getGameArtWorkList',
    data
  })
}
// 评分标签页 获取netboom评分
export function netboomScore (data) {
  return request({
    url: '/game/assess/score',
    data
  })
}

// 评分标签页 更新netboom评分
export function udpateNetboomScore (data) {
  return request({
    url: '/game/assess/score/update',
    data
  })
}

// 游戏绑定的实物商品列表
export function searchGoodsList (data) {
  return request({
    url: '/game/bind/goods/list',
    data
  })
}

// 根据id 搜索实物商品
let cancel
export function searchGoodsById (data) {
  cancel && cancel()
  return request({
    url: '/game/goods/search/byid',
    data,
    cancelToken: new CancelToken((c) => {
      cancel = c
    })
  })
}

// 游戏绑定实物商品
export function bindGoodsToGame (data) {
  return request({
    url: '/game/goods/bind',
    data
  })
}

// 解除游戏绑定实物商品
export function unbindGoodsWithGame (data) {
  return request({
    url: '/game/goods/del/bind',
    data
  })
}

// 游戏netboom 评论
export function netboomComment (data) {
  return request({
    url: '/game/assess/statics',
    data
  })
}

// steam 修改上架下架状态
export function updateArtWorkStatus (data) {
  return request({
    url: '/game/updateArtWorkStatus',
    data
  })
}
// steam 置顶评论
export function updateCommentSort (data) {
  return request({
    url: '/game/updateCommentSort',
    data
  })
}
// steam 置顶艺术品
export function updateArtWorkSort (data) {
  return request({
    url: '/game/updateArtWorkSort',
    data
  })
}
// 获取单个游戏详情 v2
export function webGetSingleGameV2 (data) {
  return request({
    url: '/game/webGetSingleGame',
    data,
    baseURL: process.env.VUE_APP_BASEURL_V2
  })
}
// 修改游戏 v2
export function updateGameV2 (data) {
  return request({
    url: '/game/updateGame',
    data,
    baseURL: process.env.VUE_APP_BASEURL_V2
  })
}
// 修改游戏 v2
export function addGameV2 (data) {
  return request({
    url: '/game/addGame',
    data,
    baseURL: process.env.VUE_APP_BASEURL_V2
  })
}

// 资源池
export function getResPoolList (data) {
  return request({
    url: '/game/getResPool',
    data,
    baseURL: process.env.VUE_APP_BASEURL_V2
  })
}

// 禁用游戏
export function updateGameEnableStatus (data) {
  return request({
    url: '/game/updateGameEnableStatus',
    data
  })
}

export function cancellationNoOff (data) {
  return request({
    url: '/schedule/cancellationNoOff',
    data
  })
}

export function domesticRepair (data) {
  return request({
    url: '/schedule/domesticRepair',
    data
  })
}

export function releaseResources (data) {
  return request({
    url: '/schedule/releaseResources',
    data
  })
}

// get game
export function getGame (id) {
  return request({
    url: `admin/games/${id}`,
    baseURL: process.env.VUE_APP_BASEURL_API,
    method: 'get'
  })
}

// get game
export function updateGame (id, data) {
  data.archive_id = data.archive_id || null
  data.feature_tags = GameFeatureTags.filter((item) => {
    return data.feature_tags.includes(item.value)
  }).map((item) => {
    return item.value
  })

  return request({
    url: `admin/games/${id}`,
    baseURL: process.env.VUE_APP_BASEURL_API,
    data,
    method: 'put'
  })
}
