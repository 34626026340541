// APP - 模块管理
export const AppModuleManagement = [
  {
    path: 'module-list',
    name: 'ModuleList',
    component: () => import('@/views/app/ModuleList.vue'),
    meta: {
      title: 'APP_ModuleList'
    }
  },
  {
    path: 'module-edit',
    name: 'APP_ModuleEdit',
    hidden: true,
    meta: {
      title: 'ModuleEdit',
      noCache: true
    },
    component: () => import('@/views/app/ModuleEdit.vue')
  },
  {
    path: 'advertisement-list',
    name: 'AdvertisementList',
    hidden: true,
    meta: {
      title: 'AdvertisementList',
      noCache: true
    },
    component: () => import('@/views/app/AdvertisementList.vue')
  },
  {
    path: 'advertisement-edit',
    name: 'AdvertisementEdit',
    hidden: true,
    meta: {
      title: 'AdvertisementEdit',
      noCache: true
    },
    component: () => import('@/views/app/AdvertisementEdit.vue')
  }
]
