import LayoutMain from '@/layouts/LayoutMain'
import LayoutIndex from '@/layouts/LayoutIndex'

export default [
  {
    path: 'bifrostIos',
    alias: '/',
    component: LayoutIndex,
    redirect: '/bifrostIos/dashboard',
    meta: {
      title: 'BifrostIos',
      isLayout: true
    },
    children: [
      {
        path: '/bifrostIos/dashboard',
        component: LayoutMain,
        children: [
          {
            path: '/bifrostIos/dashboard',
            name: 'Dashboard',
            meta: {
              title: 'Dashboard',
              icon: 'dashboard'
            },
            component: () => import('@/views/TODO')
          }
        ]
      },
      {
        path: 'mall-server',
        component: LayoutMain,
        alwaysShow: true,
        meta: {
          menu: true,
          title: '商城后台',
          icon: 'amazon'
        },
        children: [
          {
            path: 'GoodsManagement',
            component: () => import('@/views/mall-server/GoodsManagement'),
            name: 'GoodsManagement',
            meta: {
              menu: true,
              title: '商品管理'
            }
          },
          {
            path: 'OrderManagement',
            component: () => import('@/views/mall-server/OrderManagement'),
            name: 'OrderManagement',
            meta: {
              menu: true,
              title: '订单管理'
            }
          },
          {
            path: 'ReleaseProduct',
            component: () => import('@/views/mall-server/ReleaseProduct'),
            name: 'ReleaseProduct',
            hidden: true,
            meta: {
              menu: true,
              title: '发布商品'
            }
          },
          {
            path: 'EditProduct',
            hidden: true,
            component: () => import('@/views/mall-server/ReleaseProduct'),
            name: 'EditProduct',
            meta: {
              menu: true,
              title: '编辑商品'
            }
          }
        ]
      }
    ]
  }
]
