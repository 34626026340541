<template>
  <div class="Navbar">
    <div class="left">
      <div class="item">
        <i
          class="menu-toggle fa"
          :class="{
            'fa-indent': isCollapse,
            'fa-dedent': !isCollapse,
          }"
          @click="updateIsCollapse"
        />
      </div>
      <template v-if="!isHome">
        <div class="item">
          <i
            class="fa fa-home home-icon"
            @click="handleChangeProject({project: 'home'})"
          />
        </div>
        <div class="item">
          <el-select
            v-model="project"
            placeholder="请选择项目"
            size="mini"
            value-key="project"
            @change="handleChangeProject(project)"
          >
            <el-option
              v-for="item in permissionProjectList"
              :key="item.project"
              :label="item.projectName"
              :value="item"
            >
              <i
                class="fa"
                :class="{['fa-' + item.icon]: true}"
              />
              {{ item.projectName }}
            </el-option>
          </el-select>
        </div>
      </template>
    </div>
    <div class="right">
      <el-dropdown>
        <div
          class="avatar"
          :title="user.nickName"
        >
          {{ user.nickName | nickNameFilter }}
        </div>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item
            style="width: 80px;"
            @click.native="handleEditUserInfo"
          >
            修改密码
          </el-dropdown-item>
          <el-dropdown-item
            style="width: 80px;"
            divided
            @click.native="handleLogout"
          >
            退出登录
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>

    <el-drawer
      title="编辑密码"
      :visible.sync="drawerVisible"
      direction="rtl"
      size="300px"
    >
      <el-form
        ref="ruleForm"
        :model="formData"
        :rules="rules"
        label-position="left"
      >
        <el-form-item prop="currentPassWord">
          <el-input
            v-model.trim="formData.currentPassWord"
            placeholder="当前密码"
          />
        </el-form-item>
        <el-form-item prop="newPassWord">
          <el-input
            v-model.trim="formData.newPassWord"
            placeholder="新密码"
          />
        </el-form-item>
        <el-form-item prop="confirmPassWord">
          <el-input
            v-model.trim="formData.confirmPassWord"
            placeholder="确认密码"
          />
        </el-form-item>
      </el-form>
      <div style="text-align:right;">
        <el-button
          @click="drawerVisible=false"
        >
          取消
        </el-button>
        <el-button
          type="primary"
          :loading="editLoading"
          @click="handleConfirm"
        >
          确认
        </el-button>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { mapMutations, mapState, mapActions, mapGetters } from 'vuex'
import { updatePassword } from '@/api/system_user'
import router, { formatAsyncRoutes, resetRouter } from '@/router'
import ThemePicker from '@/components/ThemePicker/index.js'
/* eslint-disable vue/no-unused-components */
export default {
  name: 'Navbar',
  filters: {
    nickNameFilter (name) {
      return name.slice(0, 1).toUpperCase()
    }
  },
  mixins: [ThemePicker],
  data () {
    const validateLength = (rule, value, callback) => {
      const length = value.length
      if (length > 20 || length < 6) {
        callback(new Error('密码长度必须在6到20之间'))
      } else {
        callback()
      }
    }
    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入新密码'))
      } else {
        if (this.formData.confirmPassWord !== '') {
          this.$refs.ruleForm.validateField('confirmPassWord')
        }
        callback()
      }
    }
    const validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.formData.newPassWord) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      drawerVisible: false,
      editLoading: false,
      formData: {
        currentPassWord: '',
        newPassWord: '',
        confirmPassWord: ''
      },
      rules: {
        currentPassWord: [{ required: true, message: '请输入当前密码' }],
        newPassWord: [
          { required: true, message: '请输入新密码' },
          { validator: validatePass, trigger: 'blur' },
          { validator: validateLength, trigger: 'blur' }
        ],
        confirmPassWord: [
          { required: true, message: '请再次输入密码' },
          { validator: validatePass2, trigger: 'blur' }
        ]
      },
      project: {
        icon: 'android',
        projectName: 'Netboom-Android',
        project: 'netboomAndroid'
      }
    }
  },
  computed: {
    ...mapState('app', ['isCollapse', 'appId']),
    ...mapState('user', ['user']),
    ...mapState('permission', ['wholePermissionList']),
    ...mapGetters('app', ['projectListForSelectOption']),
    isHome () {
      return this.$route.path.startsWith('/home')
    },
    permissionProjectList () {
      const tempObjectMap = {}
      this.wholePermissionList.forEach(item => {
        if (item.startsWith('/')) {
          const poject = item.split('/')[1]
          if (!tempObjectMap[poject]) {
            tempObjectMap[poject] = true
          }
        }
      })
      return this.projectListForSelectOption.filter(item => {
        if (tempObjectMap[item.project]) return true
      })
    }
  },
  watch: {
    '$route.path': {
      immediate: true,
      handler (path, oldPath) {
        if (path !== oldPath) {
          const activeProject = location.pathname.split('/')[1] || 'home'
          this.project = this.projectListForSelectOption.filter(item => {
            return item.project === activeProject
          })[0] || {
            icon: 'android',
            projectName: 'Netboom-Android',
            project: 'netboomAndroid'
          }
          this.updateAppIdByName(activeProject)
        }
      }
    }
  },
  methods: {
    ...mapMutations('app', ['updateIsCollapse', 'updateAppIdByName']),
    ...mapActions('user', ['logout']),
    ...mapMutations('tagsView', ['DEL_ALL_VISITED_VIEWS']),
    handleLogout () {
      this.logout()
    },
    handleEditUserInfo () {
      this.drawerVisible = true
    },
    handleConfirm () {
      this.editLoading = true
      updatePassword({ id: this.user.id, ...this.formData })
        .then(res => {
          if (res.data.code === 200) {
            this.drawerVisible = false
            this.$notify({
              title: '成功',
              message: '修改密码成功!',
              customClass: 'zIndex4000',
              type: 'success'
            })
            this.formData = {
              currentPassWord: '',
              newPassWord: '',
              confirmPassWord: ''
            }
          }
        })
        .finally(() => {
          this.editLoading = false
        })
    },
    async handleChangeProject (item) {
      const project = location.pathname.split('/')[1] || 'home'
      if (project === item.project) return
      resetRouter()
      const accessRoutes = await this.$store.dispatch('permission/generateRoutes', { project: item.project })
      this.$nextTick(() => {
        router.addRoutes(formatAsyncRoutes(accessRoutes))
        this.DEL_ALL_VISITED_VIEWS()
        this.$router.push({
          name: 'Dashboard'
        })
      })
    }
  }
}
</script>

<style lang="less">
  .el-drawer{
    &:focus{
      outline: none;
    }
    .el-drawer__header{
      >span{
        &:focus{
          outline: none;
        }
      }
      .el-drawer__close-btn{
        &:focus{
          outline: none;
        }
        &:hover{
          color: @primary;
        }
      }
    }
    .el-drawer__body{
      padding: 0 30px 0 20px;
    }
  }
  .zIndex4000{
    z-index: 4000 !important;
  }
</style>
<style scoped lang="less">
  .Navbar{
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    background: white;
    width: 100%;
    box-sizing: border-box;
    padding: 0 20px 0 0;
    .left {
      display: flex;
      align-items: center;
      >.item{
        margin-right: 14px;
      }
      .home-icon{
        font-size: 24px;
        color: @primary;
      }
    }
    .menu-toggle{
      font-size: 16px;
      cursor: pointer;
      color: #9F9F9F;
      &:hover{
        color: rgba(86,68,235,0.1);
      }
    }
    .avatar{
      @w: 36px;
      width: @w;
      height: @w;
      border-radius: @w;
      background: @primary;
      cursor: pointer;
      line-height: @w;
      color: white;
      text-align: center;
    }
  }
</style>
