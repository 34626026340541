import LayoutMain from '@/layouts/LayoutMain'
import {
  AppCategoryManagement
} from '@/router/asyncRoutes/_modules'

export const APP_ROUTES = {
  path: 'app',
  redirect: 'CategoryList',
  name: 'APP',
  alwaysShow: true,
  meta: {
    title: 'APP',
    icon: 'android'
  },
  component: LayoutMain,
  children: [
    ...AppCategoryManagement
  ]
}
