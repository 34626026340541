import LayoutMain from '@/layouts/LayoutMain'
import {
  AppCategoryManagement
} from '@/router/asyncRoutes/_modules'

export const APP_ROUTES = {
  path: 'netboomH5',
  redirect: 'ModuleList',
  name: 'netboomH5',
  alwaysShow: true,
  meta: {
    title: 'netboomH5',
    icon: 'html5'
  },
  component: LayoutMain,
  children: [
    ...AppCategoryManagement
  ]
}
