<template>
  <div class="Layout">
    <div class="layout-content">
      <slot>
        Anything
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Layout'
}
</script>

<style scoped lang="less">
  .Layout{
    padding: 20px;
    .layout-content{
      background: white;
      min-height: calc(100vh - 130px);
      box-sizing: border-box;
      padding: 14px;
      border-radius: 4px;
      box-shadow: 2px 2px 2px rgba(153,153,153, 0.2);
    }
  }
</style>
