// APP - 类目管理
export const AppCategoryManagement = [
  {
    path: 'CategoryList',
    name: 'CategoryList',
    meta: {
      title: 'CategoryList'
      // noCache: true
    },
    component: () => import('@/views/app/category/CategoryList')
  },
  {
    path: 'CategoryCreate',
    name: 'CategoryCreate',
    hidden: true,
    meta: {
      title: 'CategoryCreate'
      // noCache: true
    },
    component: () => import('@/views/app/category/CreateOrEditCategory')
  },
  {
    path: 'CategoryEdit',
    name: 'CategoryEdit',
    hidden: true,
    meta: {
      title: 'CategoryEdit'
      // noCache: true
    },
    component: () => import('@/views/app/category/CreateOrEditCategory')
  },
  {
    path: 'CategoryItemList',
    name: 'CategoryItemList',
    hidden: true,
    meta: {
      title: 'CategoryItemList'
      // noCache: true
    },
    component: () => import('@/views/app/category/CategoryItemList')
  },
  {
    path: 'ADModuleCreate',
    name: 'ADModuleCreate',
    hidden: true,
    meta: {
      title: '新建产品模块'
      // noCache: true
    },
    component: () => import('@/views/app/category/CreateOrEditADModule')
  },
  {
    path: 'ADModuleEdit',
    name: 'ADModuleEdit',
    hidden: true,
    meta: {
      title: 'ADModuleEdit',
      noCache: true
    },
    component: () => import('@/views/app/category/CreateOrEditADModule')
  },
  {
    path: 'RealADModuleCreate',
    name: 'RealADModuleCreate',
    hidden: true,
    meta: {
      title: '新建模块'
      // noCache: true
    },
    component: () => import('@/views/app/category/CreateOrEditRealADModule')
  },
  {
    path: 'RealADModuleEdit',
    name: 'RealADModuleEdit',
    hidden: true,
    meta: {
      title: '编辑模块'
      // noCache: true
    },
    component: () => import('@/views/app/category/CreateOrEditRealADModule')
  },
  {
    path: 'ADList',
    name: 'ADList',
    hidden: true,
    meta: {
      title: 'ADList'
      // noCache: true
    },
    component: () => import('@/views/app/category/ADList')
  },
  {
    path: 'RealADList',
    name: 'RealADList',
    hidden: true,
    meta: {
      title: 'ADList'
      // noCache: true
    },
    component: () => import('@/views/app/category/RealADList')
  },
  {
    path: 'ADCreate',
    name: 'ADCreate',
    hidden: true,
    meta: {
      title: 'ADCreate',
      noCache: true
    },
    component: () => import('@/views/app/category/ADEdit')
  },
  {
    path: 'ADEdit',
    name: 'ADEdit',
    hidden: true,
    meta: {
      title: 'ADEdit',
      noCache: true
    },
    component: () => import('@/views/app/category/ADEdit')
  },
  {
    path: 'RealADCreate',
    name: 'RealADCreate',
    hidden: true,
    meta: {
      title: '创建',
      noCache: true
    },
    component: () => import('@/views/app/category/RealAdEdit')
  },
  {
    path: 'RealADEdit',
    name: 'RealADEdit',
    hidden: true,
    meta: {
      title: '编辑',
      noCache: true
    },
    component: () => import('@/views/app/category/RealAdEdit')
  }
]
