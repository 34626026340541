import { format } from 'fecha'

export function pseudoRandom (seed = 1) {
  return function () {
    seed = (seed * 9301 + 49297) % 233280
    return seed / (233280.0)
  }
}

const LANGUAGE_KEY = 'server-language'
export function getLanguage () {
  return localStorage.getItem(LANGUAGE_KEY)
}

export function setLanguage (language) {
  localStorage.setItem(LANGUAGE_KEY, language)
}

export function daterange2Obj (daterange) {
  if (!daterange) daterange = ['', '']
  return {
    daterange
  }
}

export function skeletonFinish (variable, options = {}) {
  if (typeof variable === 'undefined') return
  const unwatch = this.$watch(
    variable,
    (val) => {
      if (!val) {
        this.$nextTick(() => {
          this.$emit('loaded', options)
        })
        unwatch()
      }
    }
  )
}

/**
 * @param {Function} func
 * @param {number} wait
 * @param {boolean} immediate
 * @return {*}
 */
export function debounce (func, wait, immediate) {
  let timeout, args, context, timestamp, result

  const later = function () {
    // 据上一次触发时间间隔
    const last = +new Date() - timestamp

    // 上次被包装函数被调用时间间隔 last 小于设定时间间隔 wait
    if (last < wait && last > 0) {
      timeout = setTimeout(later, wait - last)
    } else {
      timeout = null
      // 如果设定为immediate===true，因为开始边界已经调用过了此处无需调用
      if (!immediate) {
        result = func.apply(context, args)
        if (!timeout) context = args = null
      }
    }
  }

  return function (...args) {
    context = this
    timestamp = +new Date()
    const callNow = immediate && !timeout
    // 如果延时不存在，重新设定延时
    if (!timeout) timeout = setTimeout(later, wait)
    if (callNow) {
      result = func.apply(context, args)
      context = args = null
    }

    return result
  }
}
/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string | null}
 */
export function parseTime (time, cFormat) {
  if (arguments.length === 0 || !time) {
    return null
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if ((typeof time === 'string')) {
      if ((/^[0-9]+$/.test(time))) {
        // support "1548221490638"
        time = parseInt(time)
      } else {
        // support safari
        // https://stackoverflow.com/questions/4310953/invalid-date-in-safari
        time = time.replace(new RegExp(/-/gm), '/')
      }
    }

    if ((typeof time === 'number') && (time.toString().length === 10)) {
      time = time * 1000
    }
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  const timeStr = format.replace(/{([ymdhisa])+}/g, (result, key) => {
    const value = formatObj[key]
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') { return ['日', '一', '二', '三', '四', '五', '六'][value] }
    return value.toString().padStart(2, '0')
  })
  return timeStr
}

/**
 * 获取指定日期段内每一天的日期
 * @param  startDate 开始日期
 * @param  endDate   结束日期
 * @return Array
 */
export function getDateFromRange ([startDate, endDate]) {
  const startTimestamp = new Date(startDate).getTime()
  const endTimestamp = new Date(endDate).getTime()

  // 计算日期段内有多少天
  const days = (endTimestamp - startTimestamp) / (24 * 60 * 60 * 1000) + 1
  const crossYear = new Date(endTimestamp).getFullYear() > new Date(startTimestamp).getFullYear()
  // 保存每天日期
  const dateList = []
  const format = crossYear ? '{y}-{m}-{d}' : '{m}-{d}'
  for (let i = 0; i < days; i++) {
    dateList.push(parseTime(startTimestamp + (24 * 60 * 60 * 1000 * i), format))
  }

  return dateList
}

export function batchQueryParamsForExport ({ params, total, size = 100, maxSize = 5000, field = 'page' }) {
  const result = []
  const totalNum = Math.min(total, maxSize)

  for (let i = 0, start = 0; start < totalNum; i++, start += size) {
    result.push({
      ...params,
      [field]: i + 1
    })
  }
  return result
}

export const getYesterdaySection = (dateFormat = 'YYYY-MM-DD HH:mm:ss') => {
  const start = new Date(new Date().toLocaleDateString()).getTime()
  const end = new Date(new Date()).getTime()
  if (dateFormat === 'timestamp') {
    return [start, end]
  }
  return [format(start, dateFormat), format(end, dateFormat)]
}

// en英语 ko韩语 in印尼 vi越南语 ms 马来语  th泰语 zh中文繁体
const langOrder = ['en', 'ko', 'in', 'vi', 'ms', 'th', 'zh']
export const getValidField = (item, field, scope) => {
  const obj = scope ? item[scope] : item
  if (obj.en) {
    return obj.en[field]
  } else {
    const index = langOrder.findIndex(lang => {
      return Boolean(obj[lang])
    })
    if (index > -1) {
      return obj[langOrder[index]][field]
    } else {
      return ''
    }
  }
}

export function computedTime (timeLeft) {
  const times = new Date(timeLeft * 1000)
  const D = Math.floor(times.getTime() / (24 * 60 * 60 * 1000)).toString()
  const H = ('0' + times.getUTCHours()).slice(-2) || '00'
  const M = ('0' + times.getUTCMinutes()).slice(-2) || '00'
  const S = ('0' + times.getUTCSeconds()).slice(-2) || '00'
  return { D, H, M, S }
}

export function round (value, exp) {
  // If the exp is undefined or zero...
  if (typeof exp === 'undefined' || +exp === 0) {
    return Math.round(value)
  }
  value = +value
  exp = +exp
  // If the value is not a number or the exp is not an integer...
  if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
    return NaN
  }
  // Shift
  value = value.toString().split('e')
  value = Math.round(+(value[0] + 'e' + (value[1] ? (+value[1] - exp) : -exp)))
  // Shift back
  value = value.toString().split('e')
  return +(value[0] + 'e' + (value[1] ? (+value[1] + exp) : exp))
}
// 本项目 select options 转换
export const formatterSelectOptionList = (data, label = 'key', valueLabel = 'value') => {
  let list = []
  if (Array.isArray(data)) {
    list = data
  } else {
    for (const keyStr in data) {
      const key = keyStr
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        const value = data[key]
        // key value 都是字符串格式
        // eslint-disable-next-line eqeqeq
        if (isNaN(key) || key == '') {
          if (isNaN(value)) {
            list.push({ [`${label}`]: key, [`${valueLabel}`]: value })
          } else {
            list.push({ [`${label}`]: value, [`${valueLabel}`]: key })
          }
        }
      }
    }
  }
  return list
}
