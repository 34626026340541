import { queryAccountGroupList, queryLogList, queryAccountList } from '@/api/rentAccount'
import { batchQueryParamsForExport } from '@/utils'

const state = {
  accountGroupInfo: {
    loading: false,
    total: 0,
    list: []
  },
  accountGroupAsOptionsInfo: {
    fresh: false,
    options: []
  },
  logListInfo: {
    loading: false,
    total: 0,
    list: []
  },
  accountListInfo: {
    loading: false,
    total: 0,
    list: []
  }
}

const mutations = {
  updateAccountGroupInfo (state, groupInfo) {
    const { total, list } = groupInfo
    state.accountGroupInfo.total = total
    state.accountGroupInfo.list = list
  },
  updateAccountGroupOptions (state, groupInfo) {
    state.accountGroupAsOptionsInfo = {
      fresh: true,
      options: groupInfo.list
    }
  },
  updateLogListInfo (state, logListInfo) {
    const { total, list } = logListInfo
    state.logListInfo.total = total
    state.logListInfo.list = list
  },
  updateAccountListInfo (state, accountListInfo) {
    const { total, list } = accountListInfo
    state.accountListInfo.total = total
    state.accountListInfo.list = list
  },
  toggleAccountListLoading (state, loading) {
    state.accountListInfo.loading = loading
  },
  toggleAccountGroupLoading (state, loading) {
    state.accountGroupInfo.loading = loading
  },
  toggleLogLoading (state, loading) {
    state.logListInfo.loading = loading
  }
}

const actions = {
  queryAccountGroupList ({ commit }, query) {
    commit('toggleAccountGroupLoading', true)
    queryAccountGroupList(query).then(res => {
      commit('updateAccountGroupInfo', res.data)
    }).finally(() => {
      commit('toggleAccountGroupLoading', false)
    })
  },
  queryAccountGroupListAsOptions ({ commit, state }) {
    queryAccountGroupList({
      page: 1,
      size: 100
    }).then(res => {
      commit('updateAccountGroupOptions', res.data)
    })
  },
  queryLogList ({ commit }, formData) {
    const query = JSON.parse(JSON.stringify(formData))
    const daterange = query.daterange || ['', '']
    delete query.daterange
    let leaseStart = ''
    let leaseEnd = ''
    if (daterange && daterange.length === 2) {
      leaseStart = Math.floor(daterange[0] / 1000)
      leaseEnd = Math.floor(daterange[1] / 1000)
    }
    let useStart = ''
    let useEnd = ''
    if (query.useEnd) {
      useStart = query.useStart * 60 * 60
      useEnd = query.useEnd * 60 * 60
    }
    commit('toggleLogLoading', true)
    queryLogList({
      ...query,
      leaseStart,
      leaseEnd,
      useStart,
      useEnd
    }).then(res => {
      commit('updateLogListInfo', res.data)
    }).finally(() => {
      commit('toggleLogLoading', false)
    })
  },
  async queryLogListForExport ({ commit }, formData) {
    const query = JSON.parse(JSON.stringify(formData))
    const daterange = query.daterange || ['', '']
    delete query.daterange
    let leaseStart = ''
    let leaseEnd = ''
    if (daterange && daterange.length === 2) {
      leaseStart = Math.floor(daterange[0] / 1000)
      leaseEnd = Math.floor(daterange[1] / 1000)
    }
    let useStart = ''
    let useEnd = ''
    if (query.useEnd) {
      useStart = query.useStart * 60 * 60
      useEnd = query.useEnd * 60 * 60
    }
    commit('toggleLogLoading', true)

    const paramsList = batchQueryParamsForExport({
      params: {
        ...query,
        size: 100,
        leaseStart,
        leaseEnd,
        useStart,
        useEnd
      },
      total: state.logListInfo.total
    })
    const resultList = await Promise.all(paramsList.map(params => queryLogList(params)))
    const result = resultList.reduce((res, item) => {
      return res.concat(item.data.list)
    }, [])
    commit('toggleLogLoading', false)
    return result
  },
  queryAccountList ({ commit }, query) {
    commit('toggleAccountListLoading', true)
    queryAccountList(query).then(res => {
      commit('updateAccountListInfo', res.data)
    }).finally(() => {
      commit('toggleAccountListLoading', false)
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
