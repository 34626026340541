import router, { formatAsyncRoutes } from './router'
import store from './store'
import { Message } from 'element-ui'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import { getToken } from '@/utils/auth' // get token from cookie

NProgress.configure({ showSpinner: false }) // NProgress Configuration

const whiteList = ['/login', '/404'] // no redirect whitelist

router.beforeEach(async (to, from, next) => {
  // 用于游戏编辑界面动态修改hash
  if (to.path === from.path && to.name === 'GameLibraryEdit') {
    next()
    return false
  }
  console.log(to, '----------')
  if (to.name !== 'profile' && to.name !== 'profileEdit') {
    localStorage.removeItem('app_key')
    localStorage.removeItem('status')
  }
  if (to.name !== 'bottom-menu' && to.name !== 'MenuEdit') {
    localStorage.removeItem('bottom_app_key')
    localStorage.removeItem('bottom_status')
  }
  if (to.name !== 'discover' && to.name !== 'discoverEdit') {
    localStorage.removeItem('discover_app_key')
    localStorage.removeItem('discover_status')
  }
  if (to.name !== 'library' && to.name !== 'libraryEdit') {
    localStorage.removeItem('library_app_key')
    localStorage.removeItem('library_status')
  }
  // start progress bar
  NProgress.start()
  // determine whether the user has logged in
  const hasToken = getToken()

  if (hasToken) {
    if (to.path === '/login') {
      // if is logged in, redirect to the home page
      next({ path: '/' })
      NProgress.done()
    } else {
      // determine whether the user has obtained his permission roles through getInfo
      const hasRoles = store.state.user.user.id
      if (hasRoles) {
        next()
      } else {
        try {
          const { roleID } = await store.dispatch('user/GetUserInfo')
          await store.dispatch('permission/queryRolesPermission', roleID)
          const project = location.pathname.split('/')[1] || 'home'
          const accessRoutes = await store.dispatch('permission/generateRoutes', { project: project === 'login' ? 'home' : project })
          router.addRoutes(formatAsyncRoutes(accessRoutes))
          // router.addRoutes(formatAsyncRoutes(asyncRoutes))
          next({ ...to, replace: true })
        } catch (error) {
          // remove token and go to login page to re-login
          await store.dispatch('user/resetToken')
          Message.error(error || 'Has Error')
          next(`/login?redirect=${to.path}`)
          NProgress.done()
        }
      }
    }
  } else {
    /* has no token */

    if (whiteList.indexOf(to.path) !== -1) {
      // in the free login whitelist, go directly
      next()
    } else {
      // other pages that do not have permission to access are redirected to the login page.
      next(`/login?redirect=${to.path}`)
      NProgress.done()
    }
  }
})

router.afterEach(() => {
  // finish progress bar
  NProgress.done()
})
