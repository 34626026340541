<template>
  <div
    v-loading="isLoading"
    class="UploadImage2"
  >
    <div class="pic-wrapper">
      <div
        v-if="url && showClose"
        class="del"
        @click="handleRemove"
      >
        <i class="el-icon-close" />
      </div>
      <el-image
        v-show="url"
        :style="previewStyle"
        fit="contain"
        class="preview"
        :src="url"
      />
    </div>
    <el-upload
      v-show="!url"
      :action="UPLOAD_URL"
      :accept="accept"
      :multiple="false"
      :file-list="fileList"
      :before-upload="handleBeforeUpload"
      :on-success="handleSuccess"
      :show-file-list="false"
    >
      <el-button
        :loading="isLoading"
        size="small"
        type="primary"
      >
        点击上传
      </el-button>
      <div
        slot="tip"
        class="el-upload__tip"
      >
        <div v-if="aspectRatio">
          宽高比 {{ `${ratio[0]} : ${ratio[1]}` }}
        </div>
        <div>支持扩展名 jpg, jpeg, png</div>
      </div>
    </el-upload>
  </div>
</template>

<script>
export default {
  name: 'UploadImage2',
  props: {
    accept: {
      type: String,
      default: 'image/jpeg,image/jpg,image/png'
    },
    ratio: {
      type: Array,
      default: () => []
    },
    previewStyle: {
      type: String,
      default: ''
    },
    url: {
      type: String,
      default: ''
    },
    fileList: {
      type: Array,
      default: () => []
    },
    showClose: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      UPLOAD_URL: process.env.VUE_APP_BASEURL + process.env.VUE_APP_UPLOAD_URL,
      isLoading: false,
      uuid: Math.random().toString(36).slice(2)
    }
  },
  computed: {
    aspectRatio () {
      if (!this.ratio.length) {
        return 0
      } else {
        const [width, height] = this.ratio
        return (width / height).toFixed(2)
      }
    }
  },
  methods: {
    handleBeforeUpload (file) {
      this.isLoading = true
      return new Promise((resolve, reject) => {
        if (!this.aspectRatio) {
          resolve()
          return true
        }
        const reader = new FileReader()
        reader.onload = (e) => {
          const data = e.target.result
          const image = new Image()
          image.onload = () => {
            const width = image.width
            const height = image.height
            const ratio = (width / height).toFixed(2)
            if (ratio === this.aspectRatio) {
              resolve()
            } else {
              this.isLoading = false
              this.$message.error(`仅支持宽高比为${this.ratio[0]}:${this.ratio[1]}的图片`)
              reject(new Error('比例错误'))
            }
          }
          image.src = data
        }
        reader.readAsDataURL(file)
      })
    },
    handleSuccess (res) {
      this.isLoading = false
      if (res.code === 200) {
        this.$emit('update:url', res.data.url)
      } else {
        this.$message.error(res.msg)
      }
    },
    handleRemove () {
      this.$emit('update:url', '')
    }
  }
}
</script>

<style lang="less">
  .UploadImage2 {
    display: flex;
    .preview {
      margin-right: 20px;
      border: 1px dashed #e5e5e5;
    }
    .el-upload__tip {
      line-height: 18px;
    }
    .pic-wrapper {
      position: relative;
      .del {
        width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 30px;
        font-size: 22px;
        position: absolute;
        z-index: 10;
        right: 24px;
        top: 4px;
        color: white;
        cursor: pointer;
      }
    }
  }
</style>
