const modulesFiles = require.context('.', true, /\.js$/)

const camelizeRE = /-(\w)/g
export const camelize = (str) => str.replace(camelizeRE, (_, c) => c ? c.toUpperCase() : '')

// const result = modulesFiles.keys().reduce((modules, modulePath) => {
//   // set './app.js' => 'app'
//   const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1')
//   if (moduleName.startsWith('_modules') || !moduleName.endsWith('/index')) {
//     return modules
//   }
//   // const projectName = camelize(moduleName.split('/')[0])
//   return [
//     ...modules,
//     modulesFiles(modulePath).default[0]
//   ]
// }, [])
const result = modulesFiles.keys().reduce((modules, modulePath) => {
  const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1')
  if (moduleName.startsWith('_modules') || !moduleName.endsWith('/index')) {
    return modules
  }
  const module = modulesFiles(modulePath).default
  if (module && module.length > 0) {
    modules.push(module[0])
  }
  return modules
}, [])

export default result
