import path from 'path'
import { constantRoutes } from '@/router'
import {
  queryRolesList,
  getRolesPermissionById
} from '@/api/permission'
import {
  querySystemUserList,
  delSystemUser,
  resetSystemUserPassword,
  updateSystemUser,
  addSystemUser
} from '@/api/system_user'
import { Notification } from 'element-ui'
import { cloneDeep } from 'lodash'
import netboomAndroid from '@/router/asyncRoutes/netboom-android'
import netboomH5 from '@/router/asyncRoutes/netboom-h5'
import bifrostIos from '@/router/asyncRoutes/bifrost-ios'
import bifrostAndroid from '@/router/asyncRoutes/bifrost-android'
import netboomIos from '@/router/asyncRoutes/netboom-ios'
import netbangAndroid from '@/router/asyncRoutes/netbang-android'
import netboomAndroid2 from '@/router/asyncRoutes/netboom-android2'
import spanishAndroid from '@/router/asyncRoutes/spanish-android'
import arabicAndroid from '@/router/asyncRoutes/arabic-android'
import home from '@/router/asyncRoutes/home'
import asyncRoutes from '@/router/asyncRoutes/gatherRoutes.js'
import tfIos from '@/router/asyncRoutes/tf-ios'
import netboomWeb from '@/router/asyncRoutes/netboom-web'
import FingertipsIOS from '@/router/asyncRoutes/FingertipsIOS'
import JoyarkGameIOS from '@/router/asyncRoutes/JoyarkGameIOS'
import HoloLinkIOS from '@/router/asyncRoutes/HoloLinkIOS'
import netboomVR from '@/router/asyncRoutes/netboomVR'
/* eslint-disable */
const asyncRoutesMap = {
  netboomAndroid,
  home,
  netboomH5,
  bifrostIos,
  bifrostAndroid,
  netboomIos,
  netbangAndroid,
  netboomWeb,
  FingertipsIOS,
  JoyarkGameIOS,
  HoloLinkIOS,
  netboomVR,
  netboomAndroid2,
  spanishAndroid,
  arabicAndroid,
  tfIos
}
/**
 * 计算 路由表对应权限 集合
 * @param routeList asyncRoutes
 * @returns {{}}
 */
/* eslint-disable */

const computedAPI2PathPermissionMap = (routeList) => {
  const tempAPI2PathPermissionMap = {}
  const recursion = (routeList, basePath = '/') => {
    const allResult = []
    routeList.forEach(item => {
      const fullPath = path.resolve(basePath, item.path)
      const result = []
      if (item.meta && item.meta.permission) {
        result.push(...item.meta.permission.map(item => item.path))
        allResult.push(...item.meta.permission.map(item => item.path))
      }
      let res
      if (item.children) {
        res = recursion(item.children, fullPath)
      }
      res = res || []
      allResult.push(...res)
      tempAPI2PathPermissionMap[fullPath] = result.concat(res)
    })
    return allResult
  }
  recursion(routeList)
  return tempAPI2PathPermissionMap
}

const API2PathPermissionMap = computedAPI2PathPermissionMap(asyncRoutes)

/**
 * 计算是否拥有目标路由权限
 * @param route
 * @param wholePermissionList
 * @param fullPath
 * @returns {boolean|*}
 */
function hasPermission (route, wholePermissionList, fullPath) {
  if (route.meta && !route.hidden) {
    return wholePermissionList.some(path => typeof path === 'string' && path.startsWith(fullPath)) ||
      wholePermissionList.some(api => (API2PathPermissionMap[fullPath] || []).includes(api))
  } else if (route.meta && typeof route.meta.passport !== 'undefined') {
    return wholePermissionList.includes(route.meta.passport.path)
  } else {
    return true
  }
}

/**
 * 生成拥有权限的菜单及权限列表(挂载菜单)
 * @param routes
 * @param wholePermissionList
 * @param basePath
 * @param isReview
 * @returns {[]}
 */
export function filterAsyncRoutes (routes, wholePermissionList, basePath = '/', isReview = false) {
  const res = []
  routes.forEach(route => {
    const tmp = cloneDeep(route)
    const fullPath = path.resolve(basePath, route.path)
    if (hasPermission(tmp, wholePermissionList, fullPath)) {
      if (tmp.children) {
        tmp.children = filterAsyncRoutes(tmp.children, wholePermissionList, fullPath, isReview)
      }
      if (isReview && tmp.meta && tmp.meta.permission) {
        tmp.meta.permission = tmp.meta.permission.filter(item => {
          return wholePermissionList.includes(item.path)
        })
      }
      res.push(tmp)
    }
  })
  return res
}

const onlyOneShowingChildFunc = (children = [], parent) => {
  let onlyOneChild = null
  const showingChildren = children.filter(item => !item.hidden)

  // When there is only one child route, the child route is displayed by default
  if (showingChildren.length === 1) {
    onlyOneChild = showingChildren[0]
    // onlyOneChild.path = path.resolve(parent.path, onlyOneChild.path)
    return onlyOneChild
  }

  // Show parent if there are no child route to display
  if (showingChildren.length === 0) {
    onlyOneChild = { ...parent, path: '', noShowingChildren: true }
    return onlyOneChild
  }

  return false
}

/**
 * 生成全量菜单及权限列表(展示内容)
 * @param routes
 * @param basePath
 * @returns {[]} 全量菜单及权限列表
 */
export const generateRoutes = (routes, basePath = '/') => {
  const res = []
  for (let route of routes) {
    if (route.hidden) { continue }

    const onlyOneShowingChild = onlyOneShowingChildFunc(route.children, route)

    if (route.children && onlyOneShowingChild && !route.alwaysShow) {
      route = onlyOneShowingChild
    }
    const data = {
      path: path.resolve(basePath, route.path),
      title: route.meta && route.meta.title
    }
    if (route.children) {
      data.children = generateRoutes(route.children, data.path)
    }

    if (route.meta && route.meta.permission) {
      data.children = cloneDeep(route.meta.permission)
    }
    res.push(data)
  }
  return res
}

export const generateRoutesPath = (routes, selectedData, basePath = '/') => {
  let res = []
  for (const route of routes) {
    if (route.hidden) { continue }
    const _path = path.resolve(basePath, route.path)
    if (route.children) {
      res = res.concat(generateRoutesPath(route.children, selectedData, _path))
    }

    if (route.meta && route.meta.permission) {
      const temp = route.meta.permission.filter(item => {
        return selectedData.includes(item.path)
      }).map(it => it.path)
      res = res.concat(temp)
    }
    res.push(_path)
  }
  return res
}

export const disableTree = (tree) => {
  const result = []
  for (const item of tree) {
    item.disabled = true
    if (item.children) {
      item.children = disableTree(item.children)
    }
    result.push(item)
  }
  return result
}

const state = {
  routes: [],
  addRoutes: [],
  rolesListInfo: {
    loading: false,
    total: 0,
    lists: []
  },
  rolesIDMap: {},
  grainedPermission: [],
  wholePermissionList: [],
  addLoading: false,
  editLoading: false,
  userListInfo: {
    loading: false,
    total: 0,
    lists: []
  }
}

const mutations = {
  SET_ROUTES: (state, routes) => {
    state.addRoutes = routes
    state.routes = constantRoutes.concat(routes)
  },
  SET_ROLES_LIST: (state, data) => {
    state.rolesListInfo.lists = data.list
    state.rolesListInfo.total = data.total
  },
  UPDATE_ROLES_LIST_LOADING: (state, loading) => {
    state.rolesListInfo.loading = loading
  },
  SET_ROLES_ID_MAP: (state, rolesList) => {
    const temp = {}
    rolesList.forEach(item => {
      temp[item.id] = item
    })
    state.rolesIDMap = temp
  },
  UPDATE_USER_LIST: (state, data) => {
    state.userListInfo.lists = data.list
    state.userListInfo.total = data.total
  },
  UPDATE_USER_LIST_LOADING: (state, loading) => {
    state.userListInfo.loading = loading
  },
  UPDATE_ROLES_PERMISSION: (state, list) => {
    state.wholePermissionList = list
  },
  UPDATE_GRAINED_PERMISSION: (state, list) => {
    state.grainedPermission = list
  },
  UPDATE_ADD_LOADING: (state, loading) => {
    state.addLoading = loading
  },
  UPDATE_EDIT_LOADING: (state, loading) => {
    state.editLoading = loading
  }
}

const actions = {
  // 获取当前用户权限
  async queryRolesPermission ({ commit }, roleId) {
    const res = await getRolesPermissionById({ id: roleId })
    const data = res.data || { authCode: [], authRouter: [] }
    commit('UPDATE_GRAINED_PERMISSION', data.authCode || [])
    commit('UPDATE_ROLES_PERMISSION', (data.authCode || []).concat(data.authRouter || ['/dashboard']))
  },

  async queryRolesList ({ commit }, payload) {
    commit('UPDATE_ROLES_LIST_LOADING', true)
    const res = await queryRolesList(payload)
    commit('UPDATE_ROLES_LIST_LOADING', false)
    commit('SET_ROLES_LIST', res.data)
    commit('SET_ROLES_ID_MAP', res.data.list || [])
  },
  async generateRoutes ({ commit, dispatch, state }, {project = 'home'}) {
    const filtersRoutes = filterAsyncRoutes(asyncRoutesMap[project], state.wholePermissionList)
    commit('SET_ROUTES', filtersRoutes)
    return filtersRoutes
  },
  querySystemUserList ({ commit }, query) {
    commit('UPDATE_USER_LIST_LOADING', true)
    return querySystemUserList(query).then(response => {
      if (response.code === 200) {
        commit('UPDATE_USER_LIST', response.data)
      }
    }).finally(() => {
      commit('UPDATE_USER_LIST_LOADING', false)
    })
  },
  delSystemUser ({ commit }, id) {
    return delSystemUser({ id })
  },
  resetSystemUserPassword ({ commit }, id) {
    return resetSystemUserPassword({ id: id })
  },
  updateSystemUser ({ commit }, data) {
    commit('UPDATE_EDIT_LOADING', true)
    return updateSystemUser(data)
      .then(response => {
        if (response.code === 200) {
          Notification({
            title: '成功',
            message: '更新用户成功!',
            type: 'success'
          })
        }
        return response
      })
      .finally(() => {
        commit('UPDATE_EDIT_LOADING', false)
      })
  },
  addSystemUser ({ commit }, data) {
    commit('UPDATE_ADD_LOADING', true)
    return addSystemUser(data)
      .then(response => {
        if (response.code === 200) {
          Notification({
            title: '成功',
            message: '新建用户成功!',
            type: 'success'
          })
        }
        return response
      })
      .finally(() => {
        commit('UPDATE_ADD_LOADING', false)
      })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
