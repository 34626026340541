import LayoutMain from '@/layouts/LayoutMain'
import {
  AppModuleManagement,
  LogManagement
} from '@/router/asyncRoutes/_modules'

export const APP_ROUTES = {
  path: 'app',
  redirect: 'ModuleList',
  name: 'APP',
  alwaysShow: true,
  meta: {
    title: 'APP',
    icon: 'android'
  },
  component: LayoutMain,
  children: AppModuleManagement
}

export const LOG_ROUTES = {
  path: 'log-management',
  redirect: 'ErrorStatistics',
  name: 'logManagement',
  meta: {
    title: 'logManagement',
    icon: 'line-chart'
  },
  component: LayoutMain,
  children: LogManagement
}
