
export const ResourcesMenu = [
  // {
  //   path: 'accountGroup',
  //   name: 'accountGroup',
  //   meta: {
  //     title: 'AccountGroup'
  //   },
  //   component: () => import('@/views/resources/account-group/Dashboard')
  // },
  // {
  //   path: 'rent-account',
  //   name: 'RentAccount',
  //   hidden: true,
  //   meta: {
  //     title: 'RentAccount'
  //   },
  //   component: () => import('@/views/resources/account-group/RentAccount')
  // },
  // {
  //   path: 'rent-account-log',
  //   name: 'RentAccountLog',
  //   hidden: true,
  //   meta: {
  //     title: 'RentAccountLog'
  //   },
  //   component: () => import('@/views/resources/account-group/RentAccountLog')
  // },
  {
    path: 'rent-account-manage',
    name: 'RentAccountManage',
    hidden: true,
    meta: {
      title: 'RentAccountManage'
    },
    component: () => import('@/views/game/RentAccountManage')
  },
  // {
  //   path: 'streaming-desktop',
  //   name: 'StreamingDesktop',
  //   meta: {
  //     title: 'StreamingDesktop'
  //   },
  //   component: () => import('@/views/resources/streaming-desktop/Dashboard')
  // },
  // {
  //   path: 'streaming-desktop-v2',
  //   name: 'StreamingDesktopV2',
  //   meta: {
  //     title: 'StreamingDesktopV2'
  //   },
  //   component: () => import('@/views/resources/streaming-desktop-v2/Dashboard')
  // },
  {
    path: 'streaming-user-link-statistics',
    name: 'UserLinkStatistics',
    hidden: true,
    meta: {
      title: 'UserLinkStatistics'
    },
    component: () => import('@/views/resources/streaming-desktop-v2/UserLinkStatistics')
  },
  // {
  //   path: 'machine',
  //   name: 'Machine',
  //   meta: {
  //     title: 'Machine'
  //   },
  //   component: () => import('@/views/resources/machine/Dashboard')
  // },
  // {
  //   path: 'machine-log',
  //   name: 'MachineLog',
  //   hidden: true,
  //   meta: {
  //     title: 'MachineLog'
  //   },
  //   component: () => import('@/views/resources/machine/MachineLog')
  // },

  {
    path: 'machine-v2',
    name: 'MachineV2',
    meta: {
      title: 'MachineV2'
    },
    component: () => import('@/views/resources/machine-v2/Dashboard')
  },
  {
    path: 'machine-log-v2',
    name: 'MachineLogV2',
    // hidden: true,
    meta: {
      title: 'MachineLogV2'
    },
    component: () => import('@/views/resources/machine-v2/MachineLog')
  },
  {
    path: 'speed-measurement-delay',
    name: '机房延迟查询',
    meta: {
      title: '机房延迟查询'
    },
    component: () => import('@/views/resources/speed-measurement-delay')
  }
]
