<template>
  <el-form
    ref="formRef"
    :model="formData"
    v-bind="$attrs"
  >
    <el-form-item
      v-for="(item, index) in headerItems"
      :key="item.value || index"
      :label="item.label"
      :prop="item.value"
    >
      <template #label>
        <slot
          name="label"
          :label="item.label"
          :row="item"
          :index="index"
        />
      </template>

      <template v-if="item.children">
        <el-select
          v-model="formData[item.value]"
          :placeholder="item.placeholder"
          :style="item.style"
        >
          <!--  :label="val.value ?? key"
            :value="val.key ?? val"
            :key="val.key ?? val" -->
          <el-option
            v-for="(val) in formatterSelectOptionList(item.children)"
            :key="val.key"
            :label="val.value"
            :value="val.key"
          />
        </el-select>
      </template>
      <template
        v-else
      >
        <template v-if="item.validate==='time'">
          <el-date-picker
            v-if="item.value"
            v-model="formData[item.value]"
            :type="item.type || 'datetime'"
            placeholder="Pick a day"
          /> <span v-if="item.value && item.value1">-</span> <el-date-picker
            v-if="item.value1"
            v-model="formData[item.value1]"
            :type="item.type || 'datetime'"
            placeholder="Pick a day"
          />
        </template>
        <!-- <template v-else-if="item.validate==='image'">
          <Uploade
            :key="formData[item.value]"
            @pictuireChange="(val) => pictuireChange(val, item)"
            :picture="formData[item.value]"
          />
        </template> -->
        <template v-else-if="item.validate==='radio'">
          <el-radio-group
            v-model="formData[item.value]"
          >
            <el-radio
              v-for="(val) in formatterSelectOptionList(item.options)"
              :key="val.key"
              :label="val.key"
            >
              {{ val.value }}
            </el-radio>
          </el-radio-group>
        </template>
        <template v-else-if="item.validate==='checkbox'">
          <el-checkbox-group
            v-model="formData[item.value]"
          >
            <el-checkbox
              v-for="(val) in formatterSelectOptionList(item.options)"
              :key="val.key"
              :label="val.key"
            />
          </el-checkbox-group>
        </template>

        <template v-else-if="item.validate === 'template'">
          <slot
            :name="item.propsName"
            :data="formData[item.value]"
            :item="item"
            :$index="index"
          />
        </template>
        <template v-else>
          <!-- :type="item.inputType || 'text'" -->
          <el-input
            v-model="formData[item.value]"
            :placeholder="item.placeholder"
            :disabled="item.disabled"
            :style="item.style"
            type="text"
            min="0"
          >
            <template
              v-if="item.append"
              #append
            >
              {{ item.append }}
            </template>
          </el-input>
        </template>
      </template>
      <template v-if="item.tooltip">
        <div
          style="display:inline"
          v-html="item.tooltip"
        />
      </template>
    </el-form-item>
    <el-form-item>
      <slot
        name="submit"
      />
    </el-form-item>
  </el-form>
</template>

<script>
// import loadmore from '@/directives/loadmore'
export default {
  name: 'HyHeader',
  props: {
    headerItems: {
      type: Array,
      default: () => []
    },
    headerForm: {
      type: Object,
      default: () => { return {} }
    }
  },
  computed: {
    formData () {
      return this.headerForm
    }
  },
  // components: {
  //   Uploade
  // },
  // data() {
  //   return {}
  // },
  // created() {},
  methods: {
    formatterSelectOptionList (data, label = 'key', valueLabel = 'value') {
      let list = []
      if (Array.isArray(data)) {
        list = data
      } else {
        for (const keyStr in data) {
          const key = keyStr
          if (Object.prototype.hasOwnProperty.call(data, key)) {
            const value = data[key]
            // key value 都是字符串格式
            // eslint-disable-next-line eqeqeq
            if (isNaN(key) || key == '') {
              if (isNaN(value)) {
                list.push({ [`${label}`]: key, [`${valueLabel}`]: value })
              } else {
                list.push({ [`${label}`]: value, [`${valueLabel}`]: key })
              }
            }
          }
        }
      }
      return list
    }
  }
}
</script>
