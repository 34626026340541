
module.exports = [
  {
    url: '/v1/permission/rolesList',
    type: 'get',
    response: _ => {
      return {
        code: 0,
        data: [
          {
            key: 'admin',
            name: '超级管理员',
            description: '描述, admin是干啥的, 有什么特殊事项',
            routes: ['/ucenter', '/ucenter/user_list', '/permission/system_user'],
            permissions: ['20001', '20002', '20005', '20005', '20006']
          },
          {
            key: 'sru234682ert',
            name: '虚拟商品管理员',
            description: '描述, 虚拟商品管理员是干啥的, 有什么特殊事项',
            routes: ['/game/tag_manage', '/system', '/system/system_log', '/permission/role'],
            permissions: ['20003', '20005', '20004']
          },
          {
            key: 'df774h348fs',
            name: '国际化管理员',
            description: '描述, 国际化管理员是干啥的, 有什么特殊事项',
            routes: ['/goods', '/goods/goods_list', '/goods/edit', '/permission/system_user', '/permission/role'],
            permissions: ['20001', '20002', '20003', '20005', '20004']
          }
        ]
      }
    }
  },
  {
    url: '/v1/permission/deleteRole',
    type: 'get',
    response: _ => {
      return {
        code: 0,
        message: 'succeed!'
      }
    }
  },
  {
    url: '/v1/permission/addRoles',
    type: 'post',
    response: _ => {
      return {
        code: 0,
        message: 'succeed!'
      }
    }
  },
  {
    url: '/v1/permission/updateRoles',
    type: 'post',
    response: _ => {
      return {
        code: 0,
        message: 'succeed!'
      }
    }
  },
  {
    url: '/v1/role/records',
    type: 'get',
    response: _ => {
      return require('./json/role_records.json')
    }
  }
]
