import {
  APPID,
  OS_PLATFORM,
  CHANNEL,
  StartMode,
  USER_AREA,
  GAME_PLATFORM
} from '@/enum'
const translateList2Map = (list) => {
  return list.reduce((map, item) => {
    map[item.value] = item
    return map
  }, {})
}

// 启动模式 -1 正常模式 0 桌面模式 1 应用模式 2租号模式(国内) 3.海外专用steam模式也是账户助手模式
export const gameStartModeList = [
  { name: '正常模式', value: StartMode.NORMAL },
  // { name: '桌面模式', value: 0 },
  { name: '应用模式', value: StartMode.APPLICATION },
  // { name: '账户助手模式', value: 2 },start_mode`  0:桌面模式 3:Steam账号助手 6:Epic账号助手 7:uplay
  { name: 'steam - 账号助手', value: StartMode.STEAM },
  { name: 'Epic - 账号助手', value: StartMode.EPIC },
  { name: 'uplay', value: StartMode.UPLAY }
]
export const gameStartModeMap = translateList2Map(gameStartModeList)

export const appModuleStatesList = [
  { name: '上架', value: 1 },
  { name: '下架', value: 2 }
]
export const appModulePositionList = [
  { name: '首页', value: '1' }
]

export const advertisementTagList = [
  { name: 'Googel Play', value: 5 },
  { name: 'H5 外链', value: 4 },
  { name: '绑定Steam', value: 7 },
  { name: '无跳转', value: 3 },
  { name: '游戏详情页', value: 2 },
  { name: '游戏试玩详情页', value: 6 },
  { name: 'H5', value: 1 },
  { name: '原生广告（谷歌）', value: 8 }, // 原生广告修改为原生广告（谷歌）
  { name: '原生广告（pangle）', value: 9 }
]

export const advertisementTagMap = translateList2Map(advertisementTagList)

export const gamePlatform = [
  { value: 0, name: '无平台' },
  { value: 1, name: 'Steam' },
  { value: 2, name: 'Epic' },
  { value: 3, name: 'Origin' },
  { value: 4, name: 'Battle.net' },
  { value: 5, name: 'Riot' }
]

export const gamePlatformMap = translateList2Map(gamePlatform)

export const supportOperationList = [
  { name: '键盘', value: '1' },
  { name: '手柄', value: '2' },
  { name: '虚拟键', value: '3' }
]

export const operationModeList = [
  { name: '触控', value: 1 },
  { name: '鼠标', value: 2 }
]

export const operationModeMap = translateList2Map(operationModeList)

export const keyboardPlanTypeList = [
  { name: '不预设', value: 0 },
  { name: '虚拟键盘', value: 1 },
  { name: '虚拟手柄', value: 2 }
]
export const keyboardPlanTypeMap = translateList2Map(keyboardPlanTypeList)
// ko韩语 zh中文繁体 en英语 in印尼  th泰语 vi越南语 ms 马来语
export const langList = [
  { name: '英语', value: 'en' }
  // { name: '中文(繁体)', value: 'zh_tw' },
  // { name: '马来', value: 'ms' },
  // { name: '越南', value: 'vi' },
  // { name: '泰语', value: 'th' },
  // { name: '韩语', value: 'ko' },
  // { name: '印尼', value: 'in' }
]

export const langMap = translateList2Map(langList)

export const accountStateList = [
  { name: '使用中', value: 1 },
  { name: '已归还', value: 2 }
]

export const accountStateMap = translateList2Map(accountStateList)

// 1.已下单 3.付款成功 5.已取消 7.已退款
export const orderStatusList = [
  { value: 1, name: '已下单' },
  { value: 3, name: '付款成功' },
  { value: 7, name: '已退款' }
]

export const sendStatusList = [
  { value: 1, name: '未发货' },
  { value: 3, name: '已发货' }
]

export const goodsTypeList = [
  { value: 1, name: '时长卡' },
  { value: 2, name: '订阅会员' }
]

export const orderSourceList = [
  { value: 1, name: 'Google play' },
  { value: 2, name: 'Apple pay' }
]

export const rechargeCountList = [
  { value: 1, name: '0次' },
  { value: 2, name: '1次' },
  { value: 3, name: '2-5次' },
  { value: 4, name: '5次以上' }
]

export const memberTypeList = [
  { value: 1, name: '会员' },
  { value: 2, name: '非会员' }
]

export const memberTypeMap = translateList2Map(memberTypeList)

export const linkStatusList = [
  { value: 4, name: '完成' },
  { value: 5, name: '中断' },
  { value: 1, name: '申请资源' },
  { value: 2, name: '建立资源' },
  { value: 3, name: '确认资源' }
]

export const linkStatusMap = translateList2Map(linkStatusList)

export const machineRoomList = [
  { value: 1, name: 'AP-USA' }
]

export const errorCodeList = [
  { value: 1, name: '001' },
  { value: 2, name: '002' },
  { value: 3, name: '003' },
  { value: 4, name: '004' }
]

export const errorTypeList = [
  { value: 1, name: '客户端报错' },
  { value: 2, name: '服务端报错' }
]

export const errorTypeMap = translateList2Map(errorTypeList)

export const evaluateTypeList = [
  { value: 1, name: '好评' },
  { value: 2, name: '差评' }
]

export const evaluateTypeMap = translateList2Map(evaluateTypeList)

export const evaluateContentList = [
  { value: 0, name: '0' },
  { value: 1, name: '1' },
  { value: 2, name: '2' },
  { value: 3, name: '3' },
  { value: 4, name: '4' }
]

export const platformList = [
  { value: 1, name: 'Steam' },
  { value: 2, name: 'Epic' }
]

export const appIdList = [
  { value: 12, name: 'bifrost' },
  { value: 13, name: 'gta5' },
  { value: 14, name: 'gta5free' },
  { value: 15, name: 'fallguys' },
  { value: 16, name: 'fallguysfree' },
  { value: 17, name: 'trinityApp' },
  { value: 20, name: 'h5netboom' }
]

export const appIdMap = translateList2Map(appIdList)

export const categoryStateList = [
  { value: 1, name: '上架' },
  { value: 2, name: '下架' }
]
export const categoryStateMap = translateList2Map(categoryStateList)

export const positionList = [
  { value: 0, name: '首页' }
]

// export const styleList = [
//   { value: 1, name: '横向列表', example: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg' },
//   { value: 2, name: '纵向列表', example: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg' },
//   { value: 3, name: '排行榜', example: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg' },
//   { value: 4, name: '分类', example: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg' },
//   { value: 5, name: 'Banner', example: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg' },
//   { value: 6, name: 'PcMode', example: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg' },
//   { value: 9, name: 'icon单列纵向', example: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg' }
// ]

// export const styleListMap = translateList2Map(styleList)

export const styleForAdList = [
  { value: 21, name: '单列多行大卡片', example: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg' },
  { value: 22, name: '单列多行小卡片', example: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg' },
  { value: 23, name: '双列多行小卡片', example: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg' },
  { value: 24, name: '三列多行小卡片', example: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg' }
]

export const styleForListMap = translateList2Map(styleForAdList)

// export const styleListImageRatio = [
//   { value: 1, ratio: [200, 351], ratioMax: [200, 259] },
//   { value: 2, ratio: [200, 111], ratioMax: [200, 91] },
//   { value: 3, ratio: [200, 111], ratioMax: [200, 91] },
//   { value: 4, ratio: [200, 111], ratioMax: [200, 91] },
//   { value: 5, ratio: [], ratioMax: [] },
//   { value: 6, ratio: [300, 103], ratioMax: [300, 97] },
//   { value: 9, ratio: [1, 1] }
// ]
// export const styleListImageRatioMap = translateList2Map(styleListImageRatio)

export const goodsStateList = [
  { value: 1, name: '已上架' },
  { value: 2, name: '下架中' }
]

export const goodsStateMap = translateList2Map(goodsStateList)

export const MallOrderStatusList = [
  { name: '待发货', value: 1 },
  { name: '运输中', value: 2 },
  { name: '已完成', value: 3 },
  { name: '已退款', value: 4 },
  { name: '未付款', value: 5 }
]

export const MallOrderStatusMap = translateList2Map(MallOrderStatusList)

export const userStateList = [
  { name: '排队中', value: 1 },
  { name: '游戏中', value: 3 },
  { name: '已注销', value: 4 }
]

export const userStateMap = translateList2Map(userStateList)

// 订单列表
export const goodsTypesList = [
  { value: 1, name: '时长卡商品' },
  { value: 2, name: '订阅商品' },
  { value: 3, name: '赠送的时长卡商品' },
  { value: 4, name: '赠送的订阅商品' },
  { value: 5, name: 'netboom迁移购买的金币' },
  { value: 6, name: 'netboom迁移赠送的金币' },
  { value: 7, name: 'netboom购买金币' }
]

export const goodsTypesMap = translateList2Map(goodsTypesList)

// 订单列表
export const ordersStatusList = [
  { value: 1, name: '已下单' },
  { value: 2, name: '待付款' },
  { value: 3, name: '付款成功' },
  { value: 4, name: '付款失败' },
  { value: 5, name: '已取消' },
  { value: 6, name: '已关闭' },
  { value: 7, name: '已退款' }
]
export const ordersStatusMap = translateList2Map(ordersStatusList)

export const blackWhiteListOptions = [
  { name: '白名单', value: 1 },
  { name: '中间区', value: 2 },
  { name: '黑名单', value: 3 }
]

export const blackWhiteListMap = translateList2Map(blackWhiteListOptions)

export const promotionStateList = [
  { name: '未开始', value: 1 },
  { name: '进行中', value: 2 },
  { name: '已结束', value: 3 }
]

export const promotionStateMap = translateList2Map(promotionStateList)

export const promotionChannelList = [
  { name: 'KOL推广-Jordan', value: 1 },
  { name: 'KOL推广-Leo', value: 2 },
  { name: 'KOL推广-田晓航', value: 3 },
  { name: 'KOL推广-周雪', value: 4 },
  { name: '自营社群-Discord', value: 5 },
  { name: '自营Tikok', value: 6 },
  { name: '自营社群-Line', value: 7 }
]

export const promotionChannelMap = translateList2Map(promotionChannelList)

export const triggerModeList = [
  { name: '邀请注册 - 赠金币', value: 1 },
  { name: '邀请首充 - 赠金币', value: 2 },
  { name: 'VR首充 - 折扣', value: 3 }
]

export const triggerModeMap = translateList2Map(triggerModeList)

export const prizeTypeList = [
  { name: '金币', value: 1 },
  { name: '会员', value: 2 }
]

export const prizeTypeMap = translateList2Map(prizeTypeList)

export const gamePlatList = [
  { name: 'netboom-ANDROID', value: '20-ANDROID' },
  { name: 'netboom-IOS', value: '20-IOS' },
  { name: 'bifrost-ANDROID', value: '12-ANDROID' },
  { name: 'bifrost-IOS', value: '12-IOS' }
]

export const rewardTypeList = [
  // { name: '线上金币商品', value: 1 },
  { name: '自定义金币数量', value: 2 }
]

export const discountRewardTypeList = [
  { name: '全部商品', value: 11 },
  { name: '全部订阅商品', value: 12 },
  { name: '全部金币商品', value: 13 }
]

export const appointDiscountRewardTypeList = [
  { name: '指定任意商品', value: 14 }
]

export const discountRewardCountList = [
  { name: '8折', value: 80 }
]

export const rewardTypetMap = {
  1: { name: '线上金币商品', value: 1 },
  2: { name: '自定义金币数量', value: 2 },
  11: { name: '全部商品', value: 11 },
  12: { name: '全部订阅商品', value: 12 },
  13: { name: '全部金币商品', value: 13 },
  14: { name: '指定任意商品', value: 14 }
}

export const osPlatformList = [
  { value: OS_PLATFORM.ANDROID, name: 'Android' },
  { value: OS_PLATFORM.IOS, name: 'IOS' }
]

export const osPlatformMap = translateList2Map(osPlatformList)

export const appList = [
  { value: APPID.Netboom, name: 'Netboom' },
  { value: APPID.Bifrost, name: 'Bifrost' }
]

export const appMap = translateList2Map(appList)

export const channelList = [
  { value: CHANNEL.Android, name: 'Android' },
  { value: CHANNEL.IOS, name: 'IOS' }
]

export const goodsStatusList = [
  { value: 1, name: '上架' },
  { value: 2, name: '下架' }
]

export const goodsStatusMap = translateList2Map(goodsStatusList)
export const categoryStateListOne = [
  { value: 1, name: '上架' },
  { value: 0, name: '下架' }
]
export const categoryStateMapq = translateList2Map(categoryStateListOne)

export const startUsingListOne = [
  { value: 1, name: '启用' },
  { value: 2, name: '禁用' }
]
export const categoryStateUsing = translateList2Map(startUsingListOne)

export const entityOrderStatusList = [
  { name: '完成支付,且未发货', value: 200 },
  { name: '完成支付,且已发货', value: 300 }
]

export const entityOrderStatusMap = translateList2Map(entityOrderStatusList)

export const timeTypeList = [
  { name: 'day', value: 1 },
  { name: 'month', value: 2 },
  { name: 'year', value: 3 }
]

export const timeTypeMap = translateList2Map(timeTypeList)

export const goodsChannelList = [
  { name: '谷歌商城', value: 1 },
  { name: 'IOS商城', value: 2 },
  { name: 'paypal', value: 3 },
  { name: 'stripe', value: 4 },
  { name: '支付宝', value: 5 },
  { name: '空中云汇', value: 6 },
  { name: 'PayerMax', value: 7 },
  { name: '微信', value: 8 },
  { name: 'pico内购', value: 9 }
]

export const goodsChannelMap = translateList2Map(goodsChannelList)

export const goodsTypelList = [
  { name: '时长卡商品', value: 1 },
  { name: '订阅商品', value: 2 },
  { name: '赠送的时长卡商品', value: 3 },
  { name: '赠送的订阅商品', value: 4 },
  { name: 'netboom迁移购买的金币', value: 5 },
  { name: 'netboom迁移赠送的金币', value: 6 },
  { name: 'netboom金币', value: 7 }
]

export const goodsTypelMap = translateList2Map(goodsTypelList)

export const userZoneList = [
  { name: '全局配置', value: USER_AREA.Other },
  { name: '历史数据', value: USER_AREA.History }
  // { name: '欧洲地区', value: USER_AREA.Europe },
  // { name: '北美地区', value: USER_AREA.America },
  // { name: '阿拉伯地区', value: USER_AREA.Arab },
  // { name: '亚洲地区', value: USER_AREA.Asia },
  // { name: '其他地区', value: USER_AREA.Other }
]

export const GAME_MODE_LIST = [
  { value: -1, name: '正常模式' },
  { value: 1, name: '应用模式' },
  { value: -2, name: '平台模式' }
]

export const GAME_PLATFORM_LIST = [
  { value: GAME_PLATFORM.Steam, name: 'Steam' },
  { value: GAME_PLATFORM.Epic, name: 'Epic' },
  { value: GAME_PLATFORM.Uplay, name: 'Uplay' }
]

export const chargeSceneMap = translateList2Map(goodsTypelList)

export const vrAppKey = [
  { value: '86b28c96a29055f46686740f335e2d18', name: '海外-quest' },
  { value: '79eb197a632b50aaaf67ef78606b693f', name: '海外-pico' },
  { value: 'c61d2093a3de758dd87def2add7bbca3', name: '国内-Pico' },
  { value: 'f64ee11103a72edb67789a9bbb517504', name: '国内-quest' },
  { value: 'fe0d54e4cb982838a48c76612734e59f', name: '渠道包' }
]

export const vrAppKeyMap = translateList2Map(vrAppKey)

export const chargeSceneList = [
  { name: 'profile', value: 'profile' },
  { name: 'gamedetail', value: 'gamedetail' },
  { name: 'queuing', value: 'queuing' },
  { name: 'streamingdesktop', value: 'streamingdesktop' },
  { name: 'reissue', value: 'reissue' }
]

export const countryCodeList = [
  { value: 'AW', name: '阿鲁巴' },
  { value: 'AF', name: '阿富汗' },
  { value: 'AO', name: '发哥拉' },
  { value: 'AI', name: '安圭拉' },
  { value: 'AL', name: '阿尔巴尼亚' },
  { value: 'AD', name: '安道尔公国' },
  { value: 'AN', name: '荷属安的列斯' },
  { value: 'AE', name: '阿拉伯联合酋长国' },
  { value: 'AR', name: '阿根廷' },
  { value: 'AM', name: '亚美尼亚' },
  { value: 'AS', name: '美属萨摩亚' },
  { value: 'TF', name: '法属南部领土' },
  { value: 'AG', name: '安提瓜和巴布达' },
  { value: 'AU', name: '澳大利亚联邦' },
  { value: 'AT', name: '奥地利' },
  { value: 'AZ', name: '阿塞拜疆' },
  { value: 'BI', name: '布隆迪' },
  { value: 'BE', name: '比利时' },
  { value: 'BJ', name: '贝宁' },
  { value: 'BF', name: '布基纳法索' },
  { value: 'BD', name: '孟加拉人民' },
  { value: 'BG', name: '保加利亚' },
  { value: 'BH', name: '巴林国' },
  { value: 'BS', name: '巴哈马联邦' },
  { value: 'BA', name: '波斯尼亚和黑塞哥维那' },
  { value: 'BY', name: '白俄罗斯' },
  { value: 'BZ', name: '伯利兹' },
  { value: 'BM', name: '百慕大群岛' },
  { value: 'BO', name: '玻利维亚' },
  { value: 'BR', name: '巴西联邦' },
  { value: 'BB', name: '巴巴多斯' },
  { value: 'BN', name: '文莱达鲁萨' },
  { value: 'BT', name: '不丹王国' },
  { value: 'BV', name: '布维岛' },
  { value: 'BW', name: '博次瓦纳' },
  { value: 'CF', name: '中非' },
  { value: 'CA', name: '加拿大' },
  { value: 'CC', name: '科科斯（基林）群岛' },
  { value: 'CH', name: '瑞士联邦' },
  { value: 'CHI', name: '智利' },
  { value: 'CHN', name: '中国' },
  { value: 'CI', name: '科特迪瓦' },
  { value: 'CM', name: '喀麦隆' },
  { value: 'CG', name: '刚果' },
  { value: 'CK', name: '库克群岛' },
  { value: 'CO', name: '哥伦比亚' },
  { value: 'KM', name: '科摩罗' },
  { value: 'CV', name: '佛得角' },
  { value: 'CR', name: '哥斯达黎加' },
  { value: 'TW', name: '中国台湾' },
  { value: 'CU', name: '古巴' },
  { value: 'CS', name: '圣诞岛' },
  { value: 'KY', name: '开曼群岛' },
  { value: 'CY', name: '塞浦路斯' },
  { value: 'CZ', name: '捷克' },
  { value: 'DE', name: '德意志联邦' },
  { value: 'DJ', name: '吉布提' },
  { value: 'DM', name: '多米尼克联邦' },
  { value: 'DK', name: '丹麦' },
  { value: 'DO', name: '多米尼加' },
  { value: 'DZ', name: '阿尔及利亚' },
  { value: 'EC', name: '厄瓜多尔' },
  { value: 'EG', name: '埃及' },
  { value: 'ER', name: '厄立特里亚国' },
  { value: 'EH', name: '西撒哈拉' },
  { value: 'ES', name: '西班牙' },
  { value: 'EE', name: '爱沙尼亚' },
  { value: 'ET', name: '埃塞俄比亚' },
  { value: 'FI', name: '芬兰' },
  { value: 'FJ', name: '斐济' },
  { value: 'FK', name: '马尔维纳斯群岛' },
  { value: 'FR', name: '法兰西' },
  { value: 'FO', name: '法罗群岛' },
  { value: 'FM', name: '密克罗尼西亚联邦' },
  { value: 'GA', name: '加蓬' },
  { value: 'GB', name: '大不列颠及北爱尔联合王国' },
  { value: 'GE', name: '格鲁吉亚' },
  { value: 'GH', name: '加纳' },
  { value: 'GI', name: '直布罗陀' },
  { value: 'GN', name: '几内亚' },
  { value: 'GP', name: '瓜德罗普' },
  { value: 'GM', name: '冈比亚' },
  { value: 'GW', name: '几内亚比绍' },
  { value: 'GQ', name: '赤道几内亚' },
  { value: 'GR', name: '希腊' },
  { value: 'GD', name: '格林纳达' },
  { value: 'GL', name: '格陵兰' },
  { value: 'GT', name: '危地马拉' },
  { value: 'GF', name: '法属圭亚那' },
  { value: 'GU', name: '关岛' },
  { value: 'GY', name: '圭亚那' },
  { value: 'HK', name: '香港' },
  { value: 'HM', name: '赫德岛和麦克唐纳岛' },
  { value: 'HN', name: '洪都拉斯' },
  { value: 'HR', name: '克罗地亚' },
  { value: 'HT', name: '海地' },
  { value: 'HU', name: '匈牙利' },
  { value: 'ID', name: '印度尼西亚' },
  { value: 'IN', name: '印度' },
  { value: 'IO', name: '英属印度洋领土' },
  { value: 'IE', name: '爱尔兰' },
  { value: 'IR', name: '伊朗' },
  { value: 'IQ', name: '伊拉克' },
  { value: 'IS', name: '冰岛' },
  { value: 'IL', name: '以色列' },
  { value: 'IT', name: '意大利' },
  { value: 'JM', name: '牙买加' },
  { value: 'JO', name: '约旦' },
  { value: 'JP', name: '日本' },
  { value: 'KZ', name: '哈萨克斯坦' },
  { value: 'KE', name: '肯尼亚' },
  { value: 'KG', name: '吉尔吉斯' },
  { value: 'KH', name: '柬埔寨' },
  { value: 'KI', name: '基里巴斯' },
  { value: 'KN', name: '圣基茨和尼维斯' },
  { value: 'KR', name: '大韩发国' },
  { value: 'KW', name: '科威特国' },
  { value: 'LA', name: '老挝' },
  { value: 'LB', name: '黎巴嫩' },
  { value: 'LR', name: '利比里亚' },
  { value: 'LY', name: '大阿拉伯利比亚' },
  { value: 'LC', name: '圣卢西亚' },
  { value: 'LI', name: '列支敦士登' },
  { value: 'LK', name: '斯里兰卡' },
  { value: 'LS', name: '莱索托' },
  { value: 'LT', name: '立陶宛' },
  { value: 'LU', name: '卢森堡' },
  { value: 'LV', name: '拉脱维亚' },
  { value: 'MO', name: '澳门' },
  { value: 'MA', name: '摩洛哥' },
  { value: 'MC', name: '摩纳哥' },
  { value: 'MD', name: '摩尔多瓦' },
  { value: 'MG', name: '马达加斯加' },
  { value: 'MV', name: '马尔代夫' },
  { value: 'MX', name: '墨西哥' },
  { value: 'MH', name: '马绍尔群岛' },
  { value: 'MK', name: '马其顿' },
  { value: 'ML', name: '马里' },
  { value: 'MT', name: '马耳他' },
  { value: 'MM', name: '缅甸' },
  { value: 'MN', name: '蒙古' },
  { value: 'MP', name: '北马里亚纳' },
  { value: 'MZ', name: '莫双比克' },
  { value: 'MR', name: '毛里塔尼亚' },
  { value: 'MS', name: '蒙特塞拉特' },
  { value: 'MQ', name: '马提尼克' },
  { value: 'MU', name: '毛里求斯' },
  { value: 'MW', name: '马拉维' },
  { value: 'MY', name: '马来西亚' },
  { value: 'YT', name: '马约特' },
  { value: 'NA', name: '纳米比亚' },
  { value: 'NC', name: '新喀里多尼亚' },
  { value: 'NE', name: '尼日尔' },
  { value: 'NF', name: '诺福克岛' },
  { value: 'NG', name: '尼日利亚' },
  { value: 'NI', name: '尼加拉瓜' },
  { value: 'NU', name: '纽埃' },
  { value: 'NL', name: '荷兰' },
  { value: 'NO', name: '挪威' },
  { value: 'NP', name: '尼泊尔' },
  { value: 'NR', name: '瑙鲁' },
  { value: 'NZ', name: '新西兰' },
  { value: 'OM', name: '阿曼苏丹国' },
  { value: 'PK', name: '巴基斯坦' },
  { value: 'PA', name: '巴拿马' },
  { value: 'PN', name: '皮特凯恩岛' },
  { value: 'PE', name: '秘鲁' },
  { value: 'PH', name: '菲律宾' },
  { value: 'PW', name: '贝劳' },
  { value: 'PG', name: '巴布亚新几内亚' },
  { value: 'PL', name: '波兰' },
  { value: 'PR', name: '波多黎各' },
  { value: 'KP', name: '朝鲜' },
  { value: 'PT', name: '葡萄牙' },
  { value: 'PY', name: '巴拉圭' },
  { value: 'PS', name: '巴勒斯坦' },
  { value: 'PF', name: '法属波利尼西亚' },
  { value: 'QA', name: '卡塔尔' },
  { value: 'RE', name: '留尼汪' },
  { value: 'RO', name: '罗马尼亚' },
  { value: 'RU', name: '俄罗斯' },
  { value: 'RW', name: '卢旺达' },
  { value: 'SA', name: '沙特阿拉伯' },
  { value: 'SD', name: '苏丹' },
  { value: 'SN', name: '塞内加尔' },
  { value: 'SG', name: '新加坡' },
  { value: 'GS', name: '南乔治岛和南双德韦奇岛' },
  { value: 'SH', name: '圣赫勒拿' },
  { value: 'SJ', name: '斯瓦尔巴群岛' },
  { value: 'SB', name: '所罗门群岛' },
  { value: 'SL', name: '塞拉利昂' },
  { value: 'SV', name: '萨尔瓦多' },
  { value: 'SM', name: '圣马力诺' },
  { value: 'SO', name: '索马里' },
  { value: 'PM', name: '圣皮埃尔和密克隆' },
  { value: 'ST', name: '圣多美和普林西比' },
  { value: 'SR', name: '苏里南' },
  { value: 'SK', name: '斯洛伐克' },
  { value: 'SI', name: '斯洛文尼亚' },
  { value: 'SE', name: '瑞内' },
  { value: 'SZ', name: '斯威士兰' },
  { value: 'SC', name: '塞舌尔' },
  { value: 'SY', name: '阿拉伯叙利亚' },
  { value: 'TC', name: '特克斯和凯科斯群岛' },
  { value: 'TD', name: '乍得' },
  { value: 'TG', name: '多哥' },
  { value: 'TH', name: '泰国' },
  { value: 'TJ', name: '塔吉克斯坦' },
  { value: 'TK', name: '托克劳' },
  { value: 'TM', name: '土库曼斯坦' },
  { value: 'TP', name: '东帝汶' },
  { value: 'TO', name: '汤加' },
  { value: 'TT', name: '特立尼达和多巴哥' },
  { value: 'TN', name: '突巴斯' },
  { value: 'TR', name: '土耳其' },
  { value: 'TV', name: '图瓦卢' },
  { value: 'TZ', name: '坦桑尼亚' },
  { value: 'UG', name: '乌干达' },
  { value: 'UA', name: '乌克兰' },
  { value: 'UM', name: '美属太平洋各群岛' },
  { value: 'UY', name: '乌拉圭东岸' },
  { value: 'US', name: '美国' },
  { value: 'UZ', name: '乌兹别克斯坦' },
  { value: 'VA', name: '梵蒂冈' },
  { value: 'VC', name: '圣文森特和格林纳丁斯' },
  { value: 'VE', name: '委内瑞拉' },
  { value: 'VG', name: '英属维京' },
  { value: 'VI', name: '美属维京群岛' },
  { value: 'VN', name: '越南' },
  { value: 'VU', name: '瓦努阿图' },
  { value: 'WF', name: '瓦利斯和富图纳群岛' },
  { value: 'WS', name: '西萨摩亚' },
  { value: 'YE', name: '也门' },
  { value: 'YU', name: '南斯拉夫' },
  { value: 'ZA', name: '南非' },
  { value: 'ZR', name: '扎伊尔' },
  { value: 'ZM', name: '赞比亚' },
  { value: 'ZW', name: '津巴布韦' },
  { value: 'CL', name: '智利' },
  { value: 'CN', name: '中国' }
]

export const countryCodeMap = translateList2Map(countryCodeList)

export const CountryAbbreviation = [
  { value: 'Andorra', name: 'AD' },
  { value: 'United Arab Emirates', name: 'AE' },
  { value: 'Afghanistan', name: 'AF' },
  { value: 'Antigua and Barbuda', name: 'AG' },
  { value: 'Anguilla', name: 'AI' },
  { value: 'Albania', name: 'AL' },
  { value: 'Armenia', name: 'AM' },
  { value: 'Angola', name: 'AO' },
  { value: 'Argentina', name: 'AR' },
  { value: 'American Samoa', name: 'AS' },
  { value: 'Austria', name: 'AT' },
  { value: 'Australia', name: 'AU' },
  { value: 'Aruba', name: 'AW' },
  { value: 'Aland Islands', name: 'AX' },
  { value: 'Azerbaijan', name: 'AZ' },
  { value: 'Bosnia and Herzegovina', name: 'BA' },
  { value: 'Barbados', name: 'BB' },
  { value: 'Bangladesh', name: 'BD' },
  { value: 'Belgium', name: 'BE' },
  { value: 'Burkina Faso', name: 'BF' },
  { value: 'Bulgaria', name: 'BG' },
  { value: 'Bahrain', name: 'BH' },
  { value: 'Burundi', name: 'BI' },
  { value: 'Benin', name: 'BJ' },
  { value: 'Saint Barthelemy', name: 'BL' },
  { value: 'Bermuda', name: 'BM' },
  { value: 'Brunei', name: 'BN' },
  { value: 'Bolivia', name: 'BO' },
  { value: 'Bonaire, Saint Eustatius and Saba', name: 'BQ' },
  { value: 'Bonaire, Sint Eustatius, and Saba', name: 'BQ' },
  { value: 'Brazil', name: 'BR' },
  { value: 'Bahamas', name: 'BS' },
  { value: 'Bhutan', name: 'BT' },
  { value: 'Botswana', name: 'BW' },
  { value: 'Belarus', name: 'BY' },
  { value: 'Belize', name: 'BZ' },
  { value: 'Canada', name: 'CA' },
  { value: 'DR Congo', name: 'CD' },
  { value: 'Democratic Republic of the Congo', name: 'CD' },
  { value: 'Central African Republic', name: 'CF' },
  { value: 'Congo Republic', name: 'CG' },
  { value: 'Republic of the Congo', name: 'CG' },
  { value: 'Switzerland', name: 'CH' },
  { value: 'Ivory Coast', name: 'CI' },
  { value: 'Cook Islands', name: 'CK' },
  { value: 'Chile', name: 'CL' },
  { value: 'Cameroon', name: 'CM' },
  { value: 'China', name: 'CN' },
  { value: 'Colombia', name: 'CO' },
  { value: 'Costa Rica', name: 'CR' },
  { value: 'Cuba', name: 'CU' },
  { value: 'Cabo Verde', name: 'CV' },
  { value: 'Cape Verde', name: 'CV' },
  { value: 'Curacao', name: 'CW' },
  { value: 'Cyprus', name: 'CY' },
  { value: 'Czechia', name: 'CZ' },
  { value: 'Czech Republic', name: 'CZ' },
  { value: 'Germany', name: 'DE' },
  { value: 'Djibouti', name: 'DJ' },
  { value: 'Denmark', name: 'DK' },
  { value: 'Dominica', name: 'DM' },
  { value: 'Dominican Republic', name: 'DO' },
  { value: 'Algeria', name: 'DZ' },
  { value: 'Ecuador', name: 'EC' },
  { value: 'Estonia', name: 'EE' },
  { value: 'Egypt', name: 'EG' },
  { value: 'Eritrea', name: 'ER' },
  { value: 'Spain', name: 'ES' },
  { value: 'Ethiopia', name: 'ET' },
  { value: 'Finland', name: 'FI' },
  { value: 'Fiji', name: 'FJ' },
  { value: 'Falkland Islands', name: 'FK' },
  { value: 'Federated States of Micronesia', name: 'FM' },
  { value: 'Micronesia', name: 'FM' },
  { value: 'Faroe Islands', name: 'FO' },
  { value: 'France', name: 'FR' },
  { value: 'Gabon', name: 'GA' },
  { value: 'United Kingdom', name: 'GB' },
  { value: 'Grenada', name: 'GD' },
  { value: 'Georgia', name: 'GE' },
  { value: 'French Guiana', name: 'GF' },
  { value: 'Guernsey', name: 'GG' },
  { value: 'Ghana', name: 'GH' },
  { value: 'Gibraltar', name: 'GI' },
  { value: 'Greenland', name: 'GL' },
  { value: 'Gambia', name: 'GM' },
  { value: 'Guinea', name: 'GN' },
  { value: 'Guadeloupe', name: 'GP' },
  { value: 'Equatorial Guinea', name: 'GQ' },
  { value: 'Greece', name: 'GR' },
  { value: 'Guatemala', name: 'GT' },
  { value: 'Guam', name: 'GU' },
  { value: 'Guinea-Bissau', name: 'GW' },
  { value: 'Guyana', name: 'GY' },
  { value: 'Hong Kong', name: 'HK' },
  { value: 'Honduras', name: 'HN' },
  { value: 'Croatia', name: 'HR' },
  { value: 'Haiti', name: 'HT' },
  { value: 'Hungary', name: 'HU' },
  { value: 'Indonesia', name: 'ID' },
  { value: 'Ireland', name: 'IE' },
  { value: 'Israel', name: 'IL' },
  { value: 'Isle of Man', name: 'IM' },
  { value: 'India', name: 'IN' },
  { value: 'British Indian Ocean Territory', name: 'IO' },
  { value: 'Iraq', name: 'IQ' },
  { value: 'Iran', name: 'IR' },
  { value: 'Iceland', name: 'IS' },
  { value: 'Italy', name: 'IT' },
  { value: 'Jersey', name: 'JE' },
  { value: 'Jamaica', name: 'JM' },
  { value: 'Jordan', name: 'JO' },
  { value: 'Japan', name: 'JP' },
  { value: 'Kenya', name: 'KE' },
  { value: 'Kyrgyzstan', name: 'KG' },
  { value: 'Cambodia', name: 'KH' },
  { value: 'Kiribati', name: 'KI' },
  { value: 'Comoros', name: 'KM' },
  { value: 'Saint Kitts and Nevis', name: 'KN' },
  { value: 'St Kitts and Nevis', name: 'KN' },
  { value: 'North Korea', name: 'KP' },
  { value: 'South Korea', name: 'KR' },
  { value: 'Kuwait', name: 'KW' },
  { value: 'Cayman Islands', name: 'KY' },
  { value: 'Kazakhstan', name: 'KZ' },
  { value: 'Laos', name: 'LA' },
  { value: 'Lebanon', name: 'LB' },
  { value: 'Saint Lucia', name: 'LC' },
  { value: 'Liechtenstein', name: 'LI' },
  { value: 'Sri Lanka', name: 'LK' },
  { value: 'Liberia', name: 'LR' },
  { value: 'Lesotho', name: 'LS' },
  { value: 'Lithuania', name: 'LT' },
  { value: 'Luxembourg', name: 'LU' },
  { value: 'Latvia', name: 'LV' },
  { value: 'Libya', name: 'LY' },
  { value: 'Morocco', name: 'MA' },
  { value: 'Monaco', name: 'MC' },
  { value: 'Moldova', name: 'MD' },
  { value: 'Montenegro', name: 'ME' },
  { value: 'Saint Martin', name: 'MF' },
  { value: 'Madagascar', name: 'MG' },
  { value: 'Marshall Islands', name: 'MH' },
  { value: 'North Macedonia', name: 'MK' },
  { value: 'Macedonia', name: 'MK' },
  { value: 'Mali', name: 'ML' },
  { value: 'Myanmar', name: 'MM' },
  { value: 'Mongolia', name: 'MN' },
  { value: 'Macao', name: 'MO' },
  { value: 'Northern Mariana Islands', name: 'MP' },
  { value: 'Martinique', name: 'MQ' },
  { value: 'Mauritania', name: 'MR' },
  { value: 'Montserrat', name: 'MS' },
  { value: 'Malta', name: 'MT' },
  { value: 'Mauritius', name: 'MU' },
  { value: 'Maldives', name: 'MV' },
  { value: 'Malawi', name: 'MW' },
  { value: 'Mexico', name: 'MX' },
  { value: 'Malaysia', name: 'MY' },
  { value: 'Mozambique', name: 'MZ' },
  { value: 'Namibia', name: 'NA' },
  { value: 'New Caledonia', name: 'NC' },
  { value: 'Niger', name: 'NE' },
  { value: 'Norfolk Island', name: 'NF' },
  { value: 'Nigeria', name: 'NG' },
  { value: 'Nicaragua', name: 'NI' },
  { value: 'Netherlands', name: 'NL' },
  { value: 'Norway', name: 'NO' },
  { value: 'Nepal', name: 'NP' },
  { value: 'Nauru', name: 'NR' },
  { value: 'Niue', name: 'NU' },
  { value: 'New Zealand', name: 'NZ' },
  { value: 'Oman', name: 'OM' },
  { value: 'Panama', name: 'PA' },
  { value: 'Peru', name: 'PE' },
  { value: 'French Polynesia', name: 'PF' },
  { value: 'Papua New Guinea', name: 'PG' },
  { value: 'Philippines', name: 'PH' },
  { value: 'Pakistan', name: 'PK' },
  { value: 'Poland', name: 'PL' },
  { value: 'Saint Pierre and Miquelon', name: 'PM' },
  { value: 'Puerto Rico', name: 'PR' },
  { value: 'Palestine', name: 'PS' },
  { value: 'Palestinian Territory', name: 'PS' },
  { value: 'Portugal', name: 'PT' },
  { value: 'Palau', name: 'PW' },
  { value: 'Paraguay', name: 'PY' },
  { value: 'Qatar', name: 'QA' },
  { value: 'Reunion', name: 'RE' },
  { value: 'Romania', name: 'RO' },
  { value: 'Serbia', name: 'RS' },
  { value: 'Russia', name: 'RU' },
  { value: 'Rwanda', name: 'RW' },
  { value: 'Saudi Arabia', name: 'SA' },
  { value: 'Solomon Islands', name: 'SB' },
  { value: 'Seychelles', name: 'SC' },
  { value: 'Sudan', name: 'SD' },
  { value: 'Sweden', name: 'SE' },
  { value: 'Singapore', name: 'SG' },
  { value: 'Slovenia', name: 'SI' },
  { value: 'Slovakia', name: 'SK' },
  { value: 'Sierra Leone', name: 'SL' },
  { value: 'San Marino', name: 'SM' },
  { value: 'Senegal', name: 'SN' },
  { value: 'Somalia', name: 'SO' },
  { value: 'Suriname', name: 'SR' },
  { value: 'South Sudan', name: 'SS' },
  { value: 'São Tomé and Príncipe', name: 'ST' },
  { value: 'El Salvador', name: 'SV' },
  { value: 'Sint Maarten', name: 'SX' },
  { value: 'Syria', name: 'SY' },
  { value: 'Eswatini', name: 'SZ' },
  { value: 'Swaziland', name: 'SZ' },
  { value: 'Turks and Caicos Islands', name: 'TC' },
  { value: 'Chad', name: 'TD' },
  { value: 'Togo', name: 'TG' },
  { value: 'Thailand', name: 'TH' },
  { value: 'Tajikistan', name: 'TJ' },
  { value: 'Timor Leste', name: 'TL' },
  { value: 'East Timor', name: 'TL' },
  { value: 'Timor-Leste', name: 'TL' },
  { value: 'Turkmenistan', name: 'TM' },
  { value: 'Tunisia', name: 'TN' },
  { value: 'Tonga', name: 'TO' },
  { value: 'Turkey', name: 'TR' },
  { value: 'Trinidad and Tobago', name: 'TT' },
  { value: 'Tuvalu', name: 'TV' },
  { value: 'Taiwan', name: 'TW' },
  { value: 'Tanzania', name: 'TZ' },
  { value: 'Ukraine', name: 'UA' },
  { value: 'Uganda', name: 'UG' },
  { value: 'United States', name: 'US' },
  { value: 'Uruguay', name: 'UY' },
  { value: 'Uzbekistan', name: 'UZ' },
  { value: 'Vatican', name: 'VA' },
  { value: 'Saint Vincent and the Grenadines', name: 'VC' },
  { value: 'St Vincent and Grenadines', name: 'VC' },
  { value: 'Venezuela', name: 'VE' },
  { value: 'British Virgin Islands', name: 'VG' },
  { value: 'U.S. Virgin Islands', name: 'VI' },
  { value: 'Vietnam', name: 'VN' },
  { value: 'Vanuatu', name: 'VU' },
  { value: 'Wallis and Futuna', name: 'WF' },
  { value: 'Samoa', name: 'WS' },
  { value: 'Kosovo', name: 'XK' },
  { value: 'Yemen', name: 'YE' },
  { value: 'Mayotte', name: 'YT' },
  { value: 'South Africa', name: 'ZA' },
  { value: 'Zambia', name: 'ZM' },
  { value: 'Zimbabwe', name: 'ZW' }

]
export const ThirdPartyPaymentTypeEnum = [
  // { value: 'stripe', name: 'Stripe' },
  { value: 'payermax', name: 'PayerMax' },
  { value: 'paypal', name: 'Paypal' },
  { value: 'airwallex', name: 'Airwallex' }
]

export const GameFeatureTags = [
  { name: '单人游戏', value: 'Single' },
  { name: '多人游戏', value: 'Online' },
  { name: '需要登录', value: 'Need Login' },
  { name: '支持手柄', value: 'Controller' },
  { name: '本地存档', value: 'Local archive' },
  { name: 'Steam云存档', value: 'Steam cloud' }
]
