<template>
  <div
    :key="project"
    :class="[wrapClass, {isCollapse: isCollapse}]"
  >
    <div
      class="logo-area"
      :class="{isCollapse: isCollapse}"
    >
      <div
        class="logo-content"
      >
        <div class="logo">
          <img
            :src="focusProject.logo"
            alt="logo"
          >
        </div>
        <div class="title">
          {{ focusProject.name }}
        </div>
      </div>
    </div>
    <el-menu
      :default-active="$route.path"
      :collapse="isCollapse"
      :collapse-transition="false"
      mode="vertical"
    >
      <template
        v-for="route in routes"
      >
        <template v-if="route.meta && route.meta.isLayout">
          <sidebar-item
            v-for="routeSub in route.children"
            :key="routeSub.path"
            :item="routeSub"
            :base-path="route.path + '/' + routeSub.path"
          />
        </template>
        <sidebar-item
          v-else
          :key="route.path"
          :project="project"
          :item="route"
          :base-path="route.path"
        />
      </template>
    </el-menu>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import SidebarItem from './SidebarItem'

export default {
  components: { SidebarItem },
  data () {
    return {
      project: 'home'
    }
  },
  computed: {
    ...mapState('app', ['isCollapse', 'appId', 'appName']),
    ...mapState('permission', ['routes']),
    ...mapGetters('app', ['projectListForNavIcon']),
    wrapClass () {
      return this.isCollapse ? 'LayoutMenu isCollapse' : 'LayoutMenu'
    },
    focusProject () {
      return this.projectListForNavIcon[this.appName] || this.projectListForNavIcon.unknown
    }
  },
  watch: {
    routes: {
      deep: true,
      handler () {
        this.project = location.pathname.split('/')[1] || 'home'
      }
    }
  }
}
</script>

<style  lang="less">
  @logo-h: 160px;
  @logo-h-min: 80px;
  .LayoutMenu{
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: @menu-width;
    overflow-y: auto;
    overflow-x: hidden;
    transition: width @menu-transition-times;
    background: white;
    box-sizing: border-box;
    padding-top: @logo-h;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 0 3px 0 rgba(0, 0, 0, 0.04);
    &.isCollapse{
      padding-top: @logo-h-min;
    }
    .logo-area{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      height: @logo-h;
      .logo-content{
        display: flex;
        flex-direction: column;
        align-items: center;
        .logo{
          @w: 60px;
          width: @w;
          height: @w;
          border-radius: @w;
          overflow: hidden;
          box-shadow: 2px 2px 2px rgba(153,153,153, 0.5);
          img{
            width: @w;
            height: @w;
          }
        }
        .title{
          text-align: center;
          font-size: 18px;
          font-weight: bold;
          margin: 10px 0;
          color: #333333;
        }
      }
      &.isCollapse{
        height: @logo-h-min;
        .logo-content{
          .logo{
            @w: 36px;
            width: @w;
            height: @w;
            border-radius: @w;
            box-shadow: 1px 1px 2px  rgba(153,153,153, 0.5);
          }
          .title{
            display: none
          }
        }
      }
    }
    &::-webkit-scrollbar {display:none}
    &.isCollapse{
       width: 54px;
     }

  }
</style>
