import LayoutMain from '@/layouts/LayoutMain'
import LayoutIndex from '@/layouts/LayoutIndex'
import {
  GameMenu,
  ResourcesMenu
} from '@/router/asyncRoutes/_modules'

export const GAME_MENU = {
  path: 'game',
  redirect: '/home/game/game-list',
  name: 'Game',
  alwaysShow: true,
  meta: {
    title: 'Game',
    icon: 'gamepad'
  },
  component: LayoutMain,
  children: GameMenu
}

export const RESOURCES_MENU = {
  path: 'resources',
  redirect: 'machine',
  name: 'Resources',
  alwaysShow: true,
  meta: {
    title: 'Resources'
    // icon: 'tv'
  },
  component: LayoutIndex,
  children: ResourcesMenu
}

// export const ADVERTISING_SPACE = {
//   // path: 'advertising-position-management',
//   // component: LayoutMain,
//   // alwaysShow: true,
//   // meta: {
//   //   title: '广告位管理',
//   //   icon: 'play-circle'
//   // },
//   // children: [
//   //   {
//   path: 'advertising-space',
//   component: () => import('@/views/operational-tool/advertising-space.vue'),
//   name: 'AdvertisingPositionManagement',
//   meta: {
//     menu: true,
//     title: '广告位设置'
//   }
//   // }
//   // {
//   //   path: 'add-advertising',
//   //   component: () => import('@/views/advertising-management/addAdv.vue'),
//   //   name: 'AddAdvertising',
//   //   meta: {
//   //     title: '新增广告位'
//   //   }
//   // },
//   // {
//   //   path: 'edit-advertising',
//   //   component: () => import('@/views/advertising-management/addAdv.vue'),
//   //   name: 'EditAdvertising',
//   //   hidden: true,
//   //   meta: {
//   //     title: '编辑广告'
//   //   }
//   // }
//   // ]

//   // path: 'resources',
//   // redirect: 'machine',
//   // name: 'Resources',
//   // alwaysShow: true,
//   // meta: {
//   //   title: 'Resources',
//   //   icon: 'tv'
//   // },
//   // component: LayoutMain,
//   // children: ResourcesMenu
// }

export const APP_REVIEW_MANAGEMENT = {
  path: 'app-review-management',
  component: LayoutIndex,
  alwaysShow: true,
  meta: {
    title: '应用审核控制'
    // icon: 'play-circle'
  },
  children: [
    {
      path: '',
      component: () => import('@/views/app-review-management/appManagement.vue'),
      name: 'AdvertisingPositionManagement',
      meta: {
        menu: true,
        title: '审核管理'
      }
    },
    {
      path: 'visitRecords',
      props: { status: 0 },
      component: () => import('@/views/app-review-management/visitRecords.vue'),
      name: 'VisitRecords',
      meta: {
        menu: true,
        title: '访问记录'
      }
    },
    {
      path: 'whiteList',
      props: { status: 1, title: '白名单' },
      component: () => import('@/views/app-review-management/visitRecords.vue'),
      name: 'WhiteList',
      meta: {
        menu: true,
        title: '白名单'
      }
    },
    {
      path: 'blackList',
      props: { status: 2, title: '黑名单' },
      component: () => import('@/views/app-review-management/visitRecords.vue'),
      name: 'BlackList',
      meta: {
        menu: true,
        title: '黑名单'
      }
    }
  ]
}
