import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './permission'
import 'normalize.css'
import './plugins'
import i18n from './i18n'
import VueClipboard from 'vue-clipboard2'
import JsonExcel from 'vue-json-excel'
Vue.component('downloadExcel', JsonExcel)
Vue.use(VueClipboard)
// import Element from 'element-ui'
// import '@/assets/styles/element-variables.scss'
// Vue.use(Element)
// .
Vue.prototype.$ELEMENT = { size: 'medium', zIndex: 2000 }
Vue.config.productionTip = false
if (process.env.VUE_APP_ENVIRONMENT !== 'dev') {
  const { mockXHR } = require('../mock')
  mockXHR()
}
new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
