import store from '@/store'

export const hasPermission = (value) => {
  const grainedPermission = store.state.permission.grainedPermission || []
  let hasPermission
  if (Array.isArray(value)) {
    hasPermission = value.some(permission => {
      return grainedPermission.includes(permission.path)
    })
  } else {
    hasPermission = grainedPermission.includes(value.path)
  }
  return hasPermission
}

function checkPermission (el, binding) {
  const { value } = binding
  if (!hasPermission(value)) {
    el.parentNode && el.parentNode.removeChild(el)
  }
}

export default {
  inserted (el, binding) {
    checkPermission(el, binding)
  },
  update (el, binding) {
    checkPermission(el, binding)
  }
}
