import request from '@/utils/request'

// 获取所有角色列表
export function queryRolesList (data) {
  return request({
    url: '/admin/getAllAdminRole',
    data
  })
}

export function deleteRole (data) {
  return request({
    url: '/admin/deleteAdminRole',
    data
  })
}
// 新建角色
export function addRole (data) {
  return request({
    url: '/admin/addAdminRole',
    data
  })
}

// 更新角色
export function updateRole (data) {
  return request({
    url: '/admin/updateAdminRole',
    data
  })
}

// 获取指定角色权限
export function getRolesPermissionById (data) {
  return request({
    url: '/admin/getSingleAdminRole',
    data
  })
}
