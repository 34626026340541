<template>
  <el-table
    ref="tableRef"
    :data="tableData"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template v-if="tableData">
      <template v-for="(c, index) in formItemProp">
        <el-table-column
          v-if="!!c.validate"
          :key="index"
          :index="c.index"
          :column-key="c.columnKey"
          :render-header="c.renderHeader"
          :sortable="c.sort"
          :sort-method="c.sortMethod"
          :sort-by="c.sortBy"
          :resizable="c.resizable"
          :align="c.align"
          :header-align="c.headerAlign"
          :class-name="c.className"
          :label-class-name="c.labelClassName"
          :selectable="c.selectable"
          :reserve-selection="c.reserveSelection == null ? false : c.reserveSelection"
          :filters="c.filters"
          :filter-placement="c.filterPlacement"
          :filter-multiple="c.filterMultiple == null ? true : c.filterMultiple"
          :filter-method="c.filterMethod"
          :filtered-value="c.filteredValue"
          :prop="c.prop"
          :label="c.label"
          :min-width="c.width"
          :show-overflow-tooltip="c.ellipsis == null ? true : c.ellipsis"
        >
          <template slot-scope="props">
            <slot
              v-if="c.validate === 'template'"
              :name="'option'"
              :tableRowData="props.row"
            />
            <template v-else-if="c.validate === 'select'">
              <div
                v-for="(selectValue, selectIndex) in formatterSelectOptionList(c.optionChildren)"
                :key="selectIndex"
              >
                <span v-if="selectValue.key == props.row[c.prop] ">
                  {{ selectValue.value }}
                </span>
              </div>
            </template>
          </template>
        </el-table-column>
        <el-table-column
          v-else
          :key="index"
          :type="c.type"
          :index="c.index"
          :column-key="c.columnKey"
          :render-header="c.renderHeader"
          :sortable="c.sort"
          :sort-method="c.sortMethod"
          :sort-by="c.sortBy"
          :resizable="c.resizable"
          :align="c.align"
          :header-align="c.headerAlign"
          :class-name="c.className"
          :label-class-name="c.labelClassName"
          :selectable="c.selectable"
          :reserve-selection="c.reserveSelection == null ? false : c.reserveSelection"
          :filters="c.filters"
          :filter-placement="c.filterPlacement"
          :filter-multiple="c.filterMultiple == null ? true : c.filterMultiple"
          :filter-method="c.filterMethod"
          :filtered-value="c.filteredValue"
          :prop="c.prop"
          :label="c.label"
          :min-width="c.width"
          :show-overflow-tooltip="c.ellipsis == null ? true : c.ellipsis"
          :formatter="c.formatter"
        />
      </template>
      <!-- 添加 开关按钮 -->
      <el-table-column
        v-if="table.status"
        width="100"
        :label="'STATUS'"
      >
        <template slot-scope="scope">
          <el-switch
            v-model="scope.row.active"
            :disabled="table.status.disabled"
            active-color="#52BEA6"
            inactive-color="#CACDD0"
            :active-value="1"
            :inactive-value="0"
          />
        </template>
      </el-table-column>
      <!-- 添加 最后的button -->
      <el-table-column
        v-if="table.tableOption"
        fixed="right"
        align="center"
        header-align="center"
        :label="'ACTION'"
        :width="table.tableOption.width"
      >
        <template slot-scope="scope">
          <el-button
            v-for="(btn, index) in table.tableOption.buttons"
            :key="index"
            type="text"
            size="small"
            @click="handleButton(scope.row, btn.methods)"
          >
            {{ btn.label }}
          </el-button>
        </template>
      </el-table-column>
    </template>
  </el-table>
</template>

<script>
// import loadmore from '@/directives/loadmore'
export default {
  name: 'VoTable',
  //  directives: {
  //   loadmore
  // },
  props: {
    // tableHeaderData: { type: Array, default: () => [] }, // 多级表头
    tableData: { type: Array, default: () => [] }, // 后端返回的数据
    formItemProp: { type: Array, default: () => [] }, // 需要显示的列
    cellStripe: { type: Boolean, default: true },
    rowStripe: { type: Boolean, default: false },

    // 需要分页时 所需数据，最少要有 {pageNo, nextPage}
    pageParam: { type: [String, Object], default: '' },

    // 是否添加border
    border: { type: Boolean, default: false },

    //  添加排序，添加开关按钮等内容时，需要传入table，
    table: {
      type: Object,
      default: () => {
        return {
          status: null
        }
      }
    },

    height: { type: [Number, String], default: null },

    maxHeight: { type: [Number, String], default: null },

    width: { type: Number, default: null },

    size: { type: String, default: '' },

    fit: { type: Boolean, default: true },

    highlightCurrentRow: { type: Boolean, default: false },

    currentRowKey: { type: [String, Number], default: '' },

    rowKey: { type: [Function, String, Number], default: null },

    showHeader: { type: Boolean, default: true },

    showSummary: { type: Boolean, default: false },

    sumText: { type: String, default: '' },

    summaryMethod: { type: Function, default: () => {} },

    rowClassName: { type: [String, Function], default: '' },

    rowStyle: { type: Object, default: () => {} },
    emptyText: { type: String, default: '暂无数据' },

    expandRowKeys: { type: Array, default: () => null },

    defaultExpandAll: { type: Boolean, default: false },

    defaultSort: { type: Object, default: () => {} },

    spanMethod: { type: Function, default: () => {} },
    cellClassName: { type: Function, default: () => '' }
  },
  // data() {
  //   return {}
  // },
  // created() {},
  methods: {
    formatterSelectOptionList (data, label = 'key', valueLabel = 'value') {
      let list = []
      if (Array.isArray(data)) {
        list = data
      } else {
        for (const keyStr in data) {
          const key = keyStr
          if (Object.prototype.hasOwnProperty.call(data, key)) {
            const value = data[key]
            // key value 都是字符串格式
            // eslint-disable-next-line eqeqeq
            if (isNaN(key) || key == '') {
              if (isNaN(value)) {
                list.push({ [`${label}`]: key, [`${valueLabel}`]: value })
              } else {
                list.push({ [`${label}`]: value, [`${valueLabel}`]: key })
              }
            }
          }
        }
      }
      return list
    }
  }
}
</script>
