import request from '@/utils/request'

export function queryPriceList (data = {}) {
  return request({
    url: '/pay/goodsList',
    data
  })
}

export function updatePriceInfo () {
  return request({
    url: '/pay/syncGoodsFromGoogle',
    data: {}
  })
}

export function addGold (data) {
  return request({
    url: '/pay/addGold',
    data
  })
}

export function addTopUp (data) {
  return request({
    url: '/pay/addTopUp',
    data
  })
}

export function moveAddSubscription (data) {
  return request({
    url: '/pay/moveAddSubscription',
    data
  })
}

export function editUserInfo (data) {
  return request({
    url: '/pay/editUserInfo',
    data
  })
}

export function addgoods (data) {
  return request({
    url: 'pay/addgoods',
    data
  })
}

export function updateStatus (data) {
  return request({
    url: '/pay/updateStatus',
    data
  })
}

export function addVrLimitTime (data) {
  return request({
    url: '/pay/addVrLimitTime',
    data
  })
}
