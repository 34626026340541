import LayoutMain from '@/layouts/LayoutMain'
import LayoutIndex from '@/layouts/LayoutIndex'
import {
  APP_ROUTES
} from '@/router/asyncRoutes/netboom-web/routesData'

export default [
  {
    path: 'netboomWeb',
    alias: '/',
    component: LayoutIndex,
    redirect: '/netboomWeb/dashboard',
    meta: {
      title: 'NetboomWeb',
      isLayout: true
    },
    children: [
      {
        path: '/netboomWeb/dashboard',
        component: LayoutMain,
        children: [
          {
            path: '/netboomWeb/dashboard',
            name: 'Dashboard',
            meta: {
              title: 'Dashboard',
              icon: 'dashboard'
            },
            component: () => import('@/views/TODO')
          }
        ]
      },
      APP_ROUTES
    ]
  }
]
