<template>
  <div
    v-loading="isLoading"
    class="UploadImage"
    :class="{ disabled }"
  >
    <el-upload
      :action="UPLOAD_URL"
      :multiple="false"
      :limit="limit"
      :file-list="fileList"
      :before-upload="handleBeforeUpload"
      :on-success="handleSuccess"
      style="width: 650px;"
      list-type="picture-card"
      :class="{
        vertical: vertical
      }"
    >
      <div class="default-card">
        <i
          slot="default"
          class="el-icon-plus"
        />
        <span class="tips">
          最多上传{{ limit }}张
          <!-- <template v-if="ratio.length">
            <br>
            比例限制 {{ ratio[0] }}: {{ ratio[1] }}
            <template v-if="ratioMax.length">
              <br>
              到 {{ ratioMax[0] }}: {{ ratioMax[1] }}
            </template>
          </template> -->
        </span>
      </div>
      <template v-slot:file="{ file }">
        <div
          @dragover.prevent=""
          @drop.prevent="handleDrop(file, $event)"
        >
          <video
            v-if="videoExtend.includes(file.extend)"
            style="width: 100%; object-fit: contain;"
            :src="file.url"
          />
          <el-image
            class="el-upload-list__item-thumbnail"
            fit="contain"
            :src="file.url"
          >
            <div
              slot="placeholder"
              class="image-slot"
            >
              加载中<span class="dot">...</span>
            </div>
          </el-image>
          <span class="el-upload-list__item-actions">
            <span v-if="fileList.length > 1">
              <i
                class="el-icon-sort"
                title="拖拽可更改顺序"
                draggable="true"
                @dragstart="handleDragstart(file, $event)"
              />
            </span>
            <span
              class="el-upload-list__item-delete"
              @click="handleRemove(file)"
            >
              <i class="el-icon-delete" />
            </span>
          </span>
        </div>
      </template>
    </el-upload>
  </div>
</template>

<script>
import NProgress from 'nprogress'
import { cloneDeep } from 'lodash'
export default {
  name: 'UploadImage',
  props: {
    limit: {
      type: Number,
      default: 1
    },
    maxSize: {
      type: Number,
      default: Infinity
    },
    ratio: {
      type: Array,
      default: () => []
    },
    ratioMax: {
      type: Array,
      default: () => []
    },
    vertical: {
      type: Boolean,
      default: false
    },
    fileList: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      NProgress,
      UPLOAD_URL: process.env.VUE_APP_BASEURL + process.env.VUE_APP_UPLOAD_URL,
      videoExtend: ['map4', 'avi', 'mov', 'rmvb', 'rm', 'flv', 'mp4', '3gp'],
      isLoading: false,
      uuid: Math.random().toString(36).slice(2)
    }
  },
  computed: {
    aspectRatio () {
      if (!this.ratio.length) {
        return 0
      } else {
        const [width, height] = this.ratio
        return (width / height).toFixed(2)
      }
    },
    aspectRatioMax () {
      if (!this.ratioMax.length) {
        return 0
      } else {
        const [width, height] = this.ratioMax
        return (width / height).toFixed(2)
      }
    }
  },
  methods: {
    handleBeforeUpload (file) {
      NProgress.start()
      this.isLoading = true
      return new Promise((resolve, reject) => {
        if (file.size > this.maxSize) {
          this.isLoading = false
          NProgress.done()
          this.$message.error('图片过大, 请重新上传. 此处大小应小于:' + this.maxSize)
          reject(new Error('图片过大'))
        }
        if (!this.aspectRatio) {
          resolve()
          return true
        }
        const reader = new FileReader()
        reader.onload = (e) => {
          const data = e.target.result
          const image = new Image()
          image.onload = () => {
            const width = image.width
            const height = image.height
            const ratio = (width / height).toFixed(2)
            if (ratio === this.aspectRatio) {
              resolve()
            } else if (this.aspectRatioMax) {
              if (ratio >= this.aspectRatio && ratio <= this.aspectRatioMax) {
                resolve()
              } else {
                this.isLoading = false
                NProgress.done()
                this.$message.error(`比例不对, 请重新上传.此处正确宽高比例为${this.ratio[0]}:${this.ratio[1]} 到 ${this.ratioMax[0]}: ${this.ratioMax[1]}之间`)
                reject(new Error('比例不对'))
              }
            } else {
              this.isLoading = false
              NProgress.done()
              this.$message.error(`比例不对, 请重新上传.此处正确宽高比例为${this.ratio[0]}:${this.ratio[1]}`)
              reject(new Error('比例不对'))
            }
          }
          image.src = data
        }
        reader.readAsDataURL(file)
      })
    },
    handleSuccess (res) {
      NProgress.done()
      this.isLoading = false
      if (res.code === 200) {
        const fileExtension = res.data.url.split('.').pop().toLowerCase()
        this.$emit('update:fileList', this.fileList.concat([{
          ...res.data,
          extend: fileExtension
        }]))
      } else {
        this.$message.error(res.msg)
      }
    },
    handleRemove (file) {
      const index = this.fileList.findIndex(item => item.url === file.url)
      if (index === -1) return
      const fileList = cloneDeep(this.fileList)
      fileList.splice(index, 1)
      this.$emit('update:fileList', fileList)
    },
    handleDragstart (file, event) {
      const index = this.fileList.findIndex(item => item.url === file.url)
      event.dataTransfer.setData('text/plain', { sourceIndex: index, uuid: this.uuid })
    },
    handleDrop (file, event) {
      const index = this.fileList.findIndex(item => item.url === file.url)
      const data = event.dataTransfer.getData('text/plain')
      try {
        const sourceData = JSON.parse(data)
        if (index === sourceData.sourceIndex || this.uuid !== sourceData.uuid) return false
        const fileList = cloneDeep(this.fileList)
        ;[fileList[index], fileList[sourceData.sourceIndex]] = [fileList[sourceData.sourceIndex], fileList[index]]
        this.$emit('update:fileList', fileList)
      } catch (e) {
        console.log(e)
      }
    }
  }
}
</script>

<style lang="less">
  .UploadImage{
     &.disabled {
      pointer-events: none;
      position: relative;
      width: fit-content;
      &::after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(245, 247, 250, 0.8);
        cursor: not-allowed;
        pointer-events: auto;
      }
    }
    .default-card {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      line-height: 1.2em;
    }
    .vertical{
      @w: 150px;
      @h: 200px;
      .el-upload--picture-card{
        width: @w;
        height: @h;
        line-height: unset;
      //   line-height: @h;
      }
      .el-upload-list__item {
        width: @w;
        height: @h;
      line-height: unset;
      }
    }
    @w: 200px;
    @h: 120px;
    .el-upload--picture-card{
      width: @w;
      height: @h;
      line-height: unset;
      // line-height: @h;
      // display: flex;
      // justify-content: center;
      // align-items: center;
    }
    .el-upload-list__item {
      width: @w;
      height: @h;
      line-height: unset;
      >div{
        width: 100%;
        height: 100%;
        text-align: center;
      }
    }
    .el-upload-list--picture-card .el-upload-list__item-thumbnail {
      width: inherit;
      height: inherit;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
    }
    .tips{
      vertical-align: super;
      font-size: 12px;
      color: #999999;
      margin-left: 6px;
      line-height: 16px !important;
    }
    .isCoverLoaded {
      .el-upload--picture-card {
        display: none;
      }
    }
  }
</style>
