import permission, { hasPermission } from './permission'
import p$ from './permissionMaps'

p$.hasPermission = hasPermission

const install = function (Vue) {
  Vue.directive('permission', permission)
  Vue.prototype.p$ = p$
}

permission.install = install
export default permission
