import { queryModuleList, queryAdvertisementList } from '@/api/APP'

const state = {
  appModuleListInfo: {
    total: 0,
    list: []
  },
  advertisementListInfo: {
    total: 0,
    list: []
  }
}

const mutations = {
  updateAppModuleListInfo (state, gameInfo) {
    state.appModuleListInfo = gameInfo
  },
  updateAdvertisementListInfo (state, gameInfo) {
    state.advertisementListInfo = gameInfo
  }
}

const actions = {
  queryModuleList ({ commit, rootState }, query) {
    const appId = rootState.app.appId
    queryModuleList({
      ...query,
      appId
    }).then(res => {
      commit('updateAppModuleListInfo', res.data)
    })
  },
  queryAdvertisementList ({ commit, rootState }, query) {
    const appId = rootState.app.appId
    queryAdvertisementList({
      ...query,
      appId
    }).then(res => {
      commit('updateAdvertisementListInfo', res.data)
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
