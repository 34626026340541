import LayoutMain from '@/layouts/LayoutMain'
import LayoutIndex from '@/layouts/LayoutIndex'
import {
  APP_ROUTES
} from '@/router/asyncRoutes/netboom-h5/routesData'

export default [
  {
    path: 'netboomH5',
    alias: '/',
    component: LayoutIndex,
    redirect: '/netboomH5/dashboard',
    meta: {
      title: 'netboomH5',
      isLayout: true
    },
    children: [
      {
        path: '/netboomH5/dashboard',
        component: LayoutMain,
        children: [
          {
            path: '/netboomH5/dashboard',
            name: 'Dashboard',
            meta: {
              title: 'Dashboard',
              icon: 'dashboard'
            },
            component: () => import('@/views/TODO')
          }
        ]
      },
      {
        path: 'examine',
        component: LayoutMain,
        redirect: 'examine/index',
        meta: {
          title: '马甲包审核开关管理',
          icon: 'lock'
        },
        children: [
          {
            path: 'index',
            name: 'h5Examine',
            meta: {
              title: '流程图',
              noCache: true
            },
            component: () => import('@/views/blacklist/flowsheet')
          },
          {
            path: 'AuditManagement',
            component: () => import('@/views/blacklist/AuditManagement'),
            name: 'AuditManagement',
            meta: {
              title: '审核开关',
              noCache: true
            }
          },

          {
            path: 'black-white-list',
            component: () => import('@/views/blacklist/BlackWhiteListH5'),
            name: 'BlackWhiteListH5',
            meta: {
              title: '黑白名单',
              noCache: true
            }
          }
        ]
      },
      APP_ROUTES
    ]
  }
]
