export default {
  route: {
    Dashboard: '概览',
    Project: '项目',
    Game: '游戏',
    GameList: '游戏库',
    GameEdit: '编辑游戏',
    GameCreate: '添加游戏',
    RentAccount: '租号管理',
    RentAccountLog: '使用记录',
    RentAccountManage: '账号列表',
    Resources: '资源查询',
    AccountGroup: '区域使用范围',
    Machine: '机器-旧',
    MachineV2: '占用状态查询',
    StreamingDesktop: '流桌面-旧',
    StreamingDesktopV2: '流桌面',
    UserLinkStatistics: '用户连接时长统计',
    MachineLog: '机器使用日志-旧',
    MachineLogV2: '分配记录查询',
    APP: 'APP管理',
    APP_ModuleList: '模块列表',
    CategoryList: '类目管理',
    CategoryCreate: '新建类目',
    CategoryEdit: '编辑类目',
    CategoryItemList: '版块管理',
    ADModuleCreate: '新建版块',
    ADModuleEdit: '编辑版块',
    ADList: '列表',
    ADEdit: '编辑',
    ADCreate: '创建',
    APP_ModuleEdit: '模块编辑',
    AdvertisementList: '列表',
    AdvertisementEdit: '编辑',
    RechargeSystem: '充值系统',
    AccountRecharge: '账号充值',
    SyncPrice: '价格同步',
    TradeManagement: '交易管理',
    OrderList: '订单列表',
    userManagement: '用户管理',
    UserList: '用户查询',
    logManagement: '日志管理',
    LinkLog: '连接日志',
    ErrorLog: '错误日志',
    ErrorStatistics: '报错统计',
    ScoreLog: '体验评分',
    Permission: '权限管理',
    UserManagement: '用户管理',
    RoleManagement: '权限设置'
  }
}
