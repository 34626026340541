import request from '@/utils/request'

// 查询所有系统用户, 无分页
export function querySystemUserList (data) {
  return request({
    url: '/admin/getAllAdminUser',
    data
  })
}

export function delSystemUser (data) {
  return request({
    url: '/admin/deleteAdminUser',
    data
  })
}

export function resetSystemUserPassword (data) {
  return request({
    url: '/admin/resetUserPassWord',
    data
  })
}

export function updateSystemUser (data) {
  return request({
    url: '/admin/updateAdminUser',
    method: 'post',
    data
  })
}

// 新建系统用户
export function addSystemUser (data) {
  return request({
    url: '/admin/addAdminUser',
    method: 'post',
    data
  })
}

// 修改密码
export function updatePassword (data) {
  return request({
    url: '/admin/updateUserPassWord',
    data
  })
}
