import './element'
import permission from '@/plugins/permission'
import Vue from 'vue'
import Layout from '@/layouts/Layout'
import BHeader from '@/components/BHeader'
import Pagination from '@/components/Pagination'
import BTextButton from '@/components/BTextButton'
import UploadImage from '@/components/UploadImage/UploadImage'
import UploadImage2 from '@/components/UploadImage/UploadImage2'
import UploadImageMultiple from '@/components/UploadImage/UploadImageMultiple'
import Hyform from '@/components/baseForm/table'
import Hyheader from '@/components/baseForm/form'

import SkeletonIndex from '@/plugins/skeleton/SkeletonIndex'

Vue.component('Layout', Layout)
Vue.component('BHeader', BHeader)
Vue.component('BTextButton', BTextButton)
Vue.component('Pagination', Pagination)
Vue.component('UploadImage', UploadImage)
Vue.component('UploadImage2', UploadImage2)
Vue.component('UploadImageMultiple', UploadImageMultiple)
Vue.component('Hyform', Hyform)
Vue.component('Hyheader', Hyheader)

// 骨架屏
Vue.component('SkeletonIndex', SkeletonIndex)

Vue.use(permission)
