<template>
  <div
    v-if="!item.hidden"
    :key="project"
    class="menu-wrapper"
  >
    <template
      v-if="isHasOneShowingChild
        && (!onlyOneChild.children||onlyOneChild.noShowingChildren)
        &&!item.alwaysShow"
    >
      <app-link
        v-if="onlyOneChild.meta"
        :to="resolvePath(onlyOneChild.path)"
      >
        <el-menu-item
          :index="resolvePath(onlyOneChild.path)"
          :class="{'submenu-title-noDropdown':!isNest}"
        >
          <i
            v-if="onlyOneChild.meta.icon"
            :class="'fa fa-fw fa-' + onlyOneChild.meta.icon"
          />
          <span
            slot="title"
            :class="{
              'sub-title-name': !onlyOneChild.meta.icon
            }"
          >{{ generateTitle(onlyOneChild.meta.title) }}</span>
        </el-menu-item>
      </app-link>
    </template>

    <el-submenu
      v-else
      ref="subMenu"
      :index="resolvePath(item.path)"
      popper-append-to-body
    >
      <template
        v-if="item.meta"
        slot="title"
      >
        <i
          v-if="item.meta.icon"
          :class="'fa fa-fw fa-' +item.meta.icon"
        />
        <span slot="title">{{ generateTitle(item.meta.title) }}</span>
      </template>
      <sidebar-item
        v-for="child in item.children"
        :key="child.path"
        :is-nest="true"
        :item="child"
        :base-path="resolvePath(child.path)"
        class="nest-menu"
      />
    </el-submenu>
  </div>
</template>

<script>
import path from 'path'
import { generateTitle } from '@/utils/i18n'
import { isExternal } from '@/utils/validate'
import AppLink from './Link'

export default {
  name: 'SidebarItem',
  components: { AppLink },
  props: {
    project: {
      type: String,
      default: 'home'
    },
    item: {
      type: Object,
      required: true
    },
    isNest: {
      type: Boolean,
      default: false
    },
    basePath: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      onlyOneChild: null,
      isHasOneShowingChild: false
    }
  },
  watch: {
    item: {
      deep: true,
      immediate: true,
      handler () {
        this.isHasOneShowingChild = this.hasOneShowingChild(this.item.children, this.item)
      }
    }
  },
  methods: {
    hasOneShowingChild (children = [], parent) {
      const showingChildren = children.filter(item => {
        if (item.hidden) {
          return false
        } else {
          // Temp set(will be used if only has one showing child)
          this.onlyOneChild = item
          return true
        }
      })

      // When there is only one child router, the child router is displayed by default
      if (showingChildren.length === 1) {
        return true
      }

      // Show parent if there are no child router to display
      if (showingChildren.length === 0) {
        this.onlyOneChild = { ...parent, path: '', noShowingChildren: true }
        return true
      }

      return false
    },
    resolvePath (routePath) {
      if (isExternal(routePath)) {
        return routePath
      }
      if (isExternal(this.basePath)) {
        return this.basePath
      }
      return path.resolve(this.basePath, routePath)
    },
    generateTitle
  }
}
</script>
<style lang="less">
  .sub-title-name{
    padding-left: 5px;
  }
  .el-submenu{
    &.is-active{
      >.el-submenu__title{
        color: @primary;
        i{
          color: inherit;
        }
      }
     }
  }
</style>
