<template>
  <el-tooltip
    effect="light"
    placement="top"
    :content="title"
  >
    <el-button
      :loading="loading"
      type="text"
      @click="$emit('click')"
    >
      <slot name="icon">
        <i :class="icon" />
      </slot>
    </el-button>
  </el-tooltip>
</template>

<script>
export default {
  name: 'BTextButton',
  props: {
    title: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    loading: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped lang="less">
  .el-button{
    padding: 0 10px;
  }
  .fa-ban{
    color: #666666;
  }
  .fa-check-circle{
    color: #13ce66;
  }
</style>
