import axios from 'axios'
import { Message } from 'element-ui'
// import LocalMocker from '@/api/_LocalMock'
import { getToken } from '@/utils/auth'
// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASEURL, // url = base url + request url
  method: 'post',
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 120000 // request timeout
})

const formatterErrorMessage = (res) => {
  let errorMessage = '<div class="ErrorItem"><span class="ErrorField">Message</span></div>'
  try {
    const data = JSON.parse(res.data)
    errorMessage += `<div class="ErrorItem"><span class="ErrorField">detail</span>:<span class="ErrorValue">${data.detail}</span></div>`
  } catch (e) {
    errorMessage = `<div class="ErrorItem"><span class="ErrorField">Message</span>: <span class="ErrorValue">${res.msg || '未知错误, 请联系管理员'}</span></div>`
  }
  // errorMessage += `<div class="ErrorItem"><span class="ErrorField">t</span>: <span class="ErrorValue">${res.t}</span></div>`
  errorMessage += `<div class="ErrorItem"><span class="ErrorField">traceId</span>: <span class="ErrorValue">${res.traceId}</span></div>`
  return errorMessage
}
// request interceptor
service.interceptors.request.use(
  config => {
    const token = getToken()
    if (token) {
      config.headers.token = token
      config.headers.Authorization = `Bearer ${token}`
    }
    // if (['dev', 'sit', 'pre', 'prod'].includes(process.env.VUE_APP_ENVIRONMENT)) {
    //   if (LocalMocker.whiteList.includes(config.url)) {
    //     config.baseURL = '/mock'
    //   }
    // }
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    // apidev.netboom域名
    if ((response.config.baseURL !== process.env.VUE_APP_BASEURL_API && response.data.code !== 200) || (response.config.baseURL === process.env.VUE_APP_BASEURL_API && (response.status >= 300 || response.status < 200))) {
      const msg = formatterErrorMessage(response.data)
      console.error(response.data)
      Message({
        message: msg,
        dangerouslyUseHTMLString: true,
        type: 'error',
        // duration: 5 * 1000
        duration: 5 * 1000
      })
      throw new Error(response.data)
    }
    return response.data
  },
  error => {
    console.log('err' + error) // for debug
    Message({
      message: error.message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export default service
