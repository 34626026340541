// 日志管理
export const LogManagement = [
  {
    path: 'ErrorStatistics',
    component: () => import('@/views/log-management/ErrorStatistics.vue'),
    name: 'ErrorStatistics',
    meta: {
      title: 'ErrorStatistics'
    }
  },
  // {
  //   path: 'LinkLog',
  //   component: () => import('@/views/log-management/LinkLog'),
  //   name: 'LinkLog',
  //   meta: {
  //     title: 'LinkLog'
  //   }
  // },
  {
    path: 'ErrorLog',
    component: () => import('@/views/log-management/ErrorLog'),
    name: 'ErrorLog',
    meta: {
      title: 'ErrorLog'
    }
  },
  {
    path: 'ScoreLog',
    component: () => import('@/views/log-management/ScoreLog'),
    name: 'ScoreLog',
    meta: {
      title: 'ScoreLog'
    }
  }
]
