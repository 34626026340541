<template>
  <div class="main-view">
    <transition name="opacity">
      <component
        :is="skeleton"
        v-show="isShowSkeleton"
      />
    </transition>
    <keep-alive :include="cachedViews">
      <RouterView
        v-show="!isShowSkeleton"
        :key="$route.path"
        @loaded="handleLoaded"
      />
    </keep-alive>
  </div>
</template>

<script>
export default {
  name: 'LayoutMainRouterView',
  data () {
    return {
      skeleton: 'SkeletonIndex',
      isLoaded: false
    }
  },
  computed: {
    cachedViews () {
      return this.$store.state.tagsView.cachedViews
    },
    isShowSkeleton () {
      if (!this.$route.meta || !this.$route.meta.skeleton) return false
      return !this.isLoaded
    }
  },
  watch: {
    '$route' (route) {
      this.isLoaded = false
      if (route.meta) {
        this.skeleton = route.meta.skeletonPage || 'SkeletonIndex'
      }
    }
  },
  methods: {
    handleLoaded () {
      this.isLoaded = true
    }
  }
}
</script>

<style lang="less">
  .main-view{
    height: 100%;
    overflow: auto;
    /*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
      background-color: transparent;
    }

    /*定义滚动条轨道 内阴影+圆角*/
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    /*定义滑块 内阴影+圆角*/
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: rgba(144,147,153,.3);
      transition: background-color .3s;
      &:hover{
        background-color: rgba(144,147,153,.5);
      }
    }
  }
  /* fade-transform */
  .fade-transform-leave-active,
  .fade-transform-enter-active {
    transition: all .5s;
  }

  .fade-transform-enter {
    opacity: 0;
    transform: translateX(-30px);
  }

  .fade-transform-leave-to {
    opacity: 0;
    transform: translateX(30px);
  }

  .opacity-enter-active, .opacity-leave-active {
    transition: opacity .8s;
  }
  .opacity-enter, .opacity-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
</style>
