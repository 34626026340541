import { queryGameList, getGameDetailInfo } from '@/api/gameList'
import { cloneDeep } from 'lodash'
const state = {
  gameListInfo: {
    total: 0,
    loading: false,
    list: []
  },
  typeTagList: [],
  descTagList: [],
  gameDetail: {}
}

const getters = {
  gameDetailBasic (state) {
    return state.gameDetail.basic || {}
  },
  gameDetailDocs (state) {
    return state.gameDetail.docs || {}
  },
  gameDetailLangs (state) {
    return state.gameDetail.langs || []
  },
  gameDetailMedias (state) {
    const {
      headImage,
      headImageVertical,
      promotionalVideo,
      promotionalImage,
      icon1x1,
      icon1x2
    } = state.gameDetail.medias || {}
    return {
      headImage: headImage || {},
      headImageVertical: headImageVertical || {},
      promotionalVideo: promotionalVideo || [],
      promotionalImage: promotionalImage || [],
      icon1x1: icon1x1 || {},
      icon1x2: icon1x2 || {}
    }
  },
  gameDetailTags (state) {
    return state.gameDetail.tags || {
      typeTags: [],
      descTags: []
    }
  },
  gameDetailPlayStatics (state) {
    return state.gameDetail.playStatics || {}
  }
}

const mutations = {
  updateGameListInfo (state, gameInfo) {
    const { total, list } = gameInfo
    state.gameListInfo.total = total
    state.gameListInfo.list = list
  },
  toggleGameListInfoLoading (state, loading) {
    state.gameListInfo.loading = loading
  },
  updateGameDetailBasic (state, basic) {
    Object.assign(
      state.gameDetail.basic,
      basic
    )
    if (basic.gameName && state.gameDetail.docs.en) {
      state.gameDetail.docs.en.gameName = basic.gameName
    }
  },
  updateGameDetailLang (state, langs) {
    Object.assign(
      state.gameDetail.langs,
      langs
    )
  },
  updateGameDocsGameName (state, docs) {
    Object.keys(docs).forEach(lang => {
      state.gameDetail.docs[lang].gameName = docs[lang].gameName
    })
    state.gameDetail.basic.gameName = state.gameDetail.docs.en.gameName || state.gameDetail.basic.gameName
  },
  updateGameDocsDesc (state, docs) {
    Object.keys(docs).forEach(lang => {
      state.gameDetail.docs[lang].gameDesc = docs[lang].gameDesc
    })
  },
  updateGameDocsDetail (state, docs) {
    Object.keys(docs).forEach(lang => {
      state.gameDetail.docs[lang].gameDetail = docs[lang].gameDetail
    })
  },
  updateGameDetailMediasVideo (state, videoList) {
    state.gameDetail.medias.promotionalVideo = videoList
  },
  updateGameDetailMediasImage (state, imageList) {
    state.gameDetail.medias.promotionalImage = imageList
  },
  updateGameDetailMediasHeaderImage (state, url) {
    if (!state.gameDetail.medias.headImage) {
      state.gameDetail.medias.headImage = {}
    }
    state.gameDetail.medias.headImage.urls = url
  },
  updateGameDetailMediasHeaderImageVertical (state, url) {
    if (!state.gameDetail.medias.headImageVertical) {
      state.gameDetail.medias.headImageVertical = {}
    }
    state.gameDetail.medias.headImageVertical.urls = url
  },
  updateGameDetailMediasIcon1x1 (state, url) {
    if (!state.gameDetail.medias.icon1x1) {
      state.gameDetail.medias.icon1x1 = {}
    }
    state.gameDetail.medias.icon1x1.urls = url
  },
  updateGameDetailMediasIcon1x2 (state, url) {
    if (!state.gameDetail.medias.icon1x2) {
      state.gameDetail.medias.icon1x2 = {}
    }
    state.gameDetail.medias.icon1x2.urls = url
  },
  updateGameDetailTypeTags (state, typeTags) {
    state.gameDetail.tags.typeTags = typeTags
  },
  updateGameDetailDescTags (state, descTags) {
    state.gameDetail.tags.descTags = descTags
  },
  updateGameDetail (state, data) {
    state.gameDetail = data
  },
  updateTypeTagList (state, obj) {
    state.typeTagList = ((obj || { groupList: [{}] }).groupList[0] || {}).tagList || []
  },
  updateDescTagList (state, obj) {
    state.descTagList = (obj || {}).groupList || []
  }
}

const actions = {
  queryGameList ({ commit }, query) {
    const params = cloneDeep(query)
    if (params.startMode === '') params.startMode = -3
    commit('toggleGameListInfoLoading', true)
    queryGameList(params).then(res => {
      commit('toggleGameListInfoLoading', false)
      commit('updateGameListInfo', res.data)
    })
  },
  getGameDetailInfo ({ commit }, query) {
    return getGameDetailInfo(query).then(res => {
      return res
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
