import { queryPriceList } from '@/api/RechargeSystem'

const state = {
  priceListInfo: {
    loading: false,
    total: 0,
    list: []
  }
}

const mutations = {
  updatePriceList (state, listInfo) {
    const { goodsList } = listInfo
    state.priceListInfo.list = goodsList
  },
  updatePriceTotal (state, total) {
    state.priceListInfo.total = total
  },
  togglePriceListLoading (state, loading) {
    state.priceListInfo.loading = loading
  }
}

const actions = {
  queryPriceList ({ commit }, payload) {
    commit('togglePriceListLoading', true)
    queryPriceList(payload).then(res => {
      if (res.code === 200) {
        commit('updatePriceList', res.data)
        commit('updatePriceTotal', res.data.total || 0)
      }
    })
      .finally(() => {
        commit('togglePriceListLoading', false)
      })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
