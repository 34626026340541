const gameList = require('./json/gamelist.json')
const _ = require('lodash')
const Mock = require('mockjs')
const Random = Mock.Random
module.exports = [
  {
    url: '/gameList',
    type: 'post',
    response: (req) => {
      // const { body, query } = req
      return {
        code: 200,
        data: {
          total: gameList.length,
          list: _.shuffle(gameList).slice(0, 8)
        }
      }
    }
  },
  {
    url: '/accountGroupList',
    type: 'post',
    response: () => {
      return {
        code: 200,
        data: {
          total: 123,
          'list|10': [
            {
              'groupId|43535-345563': 43535,
              groupName: () => Random.name(),
              groupDes: () => Random.title(),
              'accountTotal|5-34': 10,
              'accountNo|5-20': 12,
              'accountUsing|5-12': 12,
              'state|1-2': 1
            }
          ]
        }
      }
    }
  },
  {
    url: '/queryLogList',
    type: 'post',
    response: () => {
      return {
        code: 200,
        data: {
          total: 123,
          'list|10': [
            {
              'userId|43535-345563': 43535,
              accountName: () => Random.name(),
              group: () => Random.name(),
              startTime: () => Random.date() + ' ' + Random.time(),
              time: () => Random.date(),
              'state|1-2': 1
            }
          ]
        }
      }
    }
  },
  {
    url: '/queryAccountList',
    type: 'post',
    response: () => {
      return {
        code: 200,
        data: {
          total: 123,
          'list|10': [
            {
              'accountId|43535-345563': 43535,
              account: () => Random.name(),
              accountPassword: () => Random.name(),
              accountDes: () => Random.title(),
              state: () => Random.pick(['使用中', '已禁用', '空闲中']),
              createTime: () => Random.date() + ' ' + Random.time(),
              'status|1-2': 1
            }
          ]
        }
      }
    }
  }
]
