import { queryModelStyleList } from '@/api/selectOptions.js'

const translateList2Map = (list) => {
  return list.reduce((map, item) => {
    map[item.value] = item
    return map
  }, {})
}
const state = {
  modelStyleList: []
}

const getters = {
  modelStyleListMap (state) {
    return translateList2Map(state.modelStyleList || [])
  }
}

const mutations = {
  UPDATE_MODEL_STYLELIST (state, list) {
    state.modelStyleList = list
  }
}

const actions = {
  queryModelStyleList ({ state, commit }) {
    if (state.modelStyleList.length) return
    queryModelStyleList()
      .then(res => {
        if (res.code === 200) {
          commit('UPDATE_MODEL_STYLELIST', res?.data || [])
        }
      })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
