import Vue from 'vue'
import VueRouter from 'vue-router'
import LayoutIndex from '@/layouts/LayoutIndex'
import LayoutMain from '@/layouts/LayoutMain'

import { constantRoutes } from './constantRoutes/index'

// import { asyncRoutes } from '@/router/asyncRoutes/index'
import asyncRoutes from '@/router/asyncRoutes/home'
export { constantRoutes }
export { asyncRoutes }
Vue.use(VueRouter)

export function formatAsyncRoutes (asyncRoutes) {
  return [
    {
      path: '/',
      component: LayoutIndex,
      children: asyncRoutes
    },
    {
      path: '*',
      component: LayoutMain,
      hidden: true,
      children: [
        {
          path: '',
          component: () => import('@/views/error/_404')
        }
      ]
    }
  ]
}

const routes = [
  ...constantRoutes
]

const createRouter = () => new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

const router = createRouter()

export function resetRouter () {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

export default router
