/**
 * 细粒度权限映射表
 * 模块.权限
 *
 * 同一模块,code前4位保持一致
 */
const maps = {
  permission: {
    addRoles: { name: '添加角色', code: '10001' },
    editRoles: { name: '编辑角色', code: '10002' },
    delRoles: { name: '删除角色', code: '10003' }
  },
  game: {
    base: { name: '基本功能', code: '10010' },
    addGame: { name: '添加游戏', code: '10011' },
    editGame: { name: '编辑游戏', code: '10012' }
  }
}

/**
 * 将 { name: '添加用户', code: 20001 } 转为 { title: '添加用户', path: 20001 } 形式
 * 用于与 routes 保持一致, 以便于生成tree
 *
 * 而 原maps, 需保持语义化, 用于与后端沟通
 */
Object.keys(maps).forEach(field => {
  const mapsItem = maps[field]
  Object.keys(mapsItem).forEach(it => {
    const { name, code } = mapsItem[it]
    Object.defineProperty(mapsItem, it, {
      get () {
        return {
          title: name,
          path: code
        }
      }
    })
  })
})
export default maps
