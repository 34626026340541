import $p from '@/plugins/permission/permissionMaps'

export const GameMenu = [
  {
    path: 'game-list',
    name: 'GameList',
    component: () => import('@/views/game/GameList.vue'),
    meta: {
      title: 'GameList',
      // skeleton: true,
      permission: [
        $p.game.base,
        $p.game.addGame,
        $p.game.editGame
      ]
    }
  },
  {
    path: 'game-create',
    name: 'GameCreate',
    hidden: true,
    meta: {
      title: 'GameCreate',
      // noCache: true,
      // hidden: true. 说明此路由不是菜单路由,不会显示在菜单中, 也不会显示在权限配置中. (权限配置按模块设计, 只有菜单模块)
      // 这种页面的权限,由按钮权限配置, 与hidden: true同时存在时生效. 当passport存在于权限表时, 用户具有本路由访问权限
      // 举例: 如果用户具有addGame权限, 那么就会显示`添加游戏`按钮, 同时可以访问passport为`addGame`的路由
      // 反之, 就不会显示`添加游戏`按钮, 也不可以访问passport为`addGame`的路由, 即使是通过收藏的网址也不可以.
      // 此类页面的细粒度权限, 交由菜单模块配置. 就是说, 不要在这里添加`permission: []`, GameEdit的permission, 应该在GameList处配置.
      passport: $p.game.addGame
    },
    component: () => import('@/views/game/game-edit/Index.vue')
  },
  {
    path: 'game-edit',
    name: 'GameEditIndex',
    hidden: true,
    meta: {
      title: 'GameEditIndex',
      // noCache: true,
      passport: $p.game.editGame
    },
    component: () => import('@/views/game/game-edit/Index.vue')
  },
  {
    path: 'wish-list',
    name: 'WishList',
    meta: {
      title: '心愿单'
    },
    component: () => import('@/views/game/WishList')
  }
]
