import request from '@/utils/request'

export function queryAccountGroupList (data) {
  return request({
    url: '/game/groupList',
    data
  })
}

export function updateAccountGroup (data) {
  return request({
    url: '/game/groupAdd',
    data
  })
}

export function queryLogList (data) {
  return request({
    url: '/game/accountLog',
    data
  })
}

export function queryAccountList (data) {
  return request({
    url: '/game/accountList',
    data
  })
}

export function updateOrCreateAccount (data) {
  return request({
    url: '/game/accountAdd',
    data
  })
}

export function bulkAccountAdd (data) {
  return request({
    url: '/game/bulkAccountAdd',
    data
  })
}

export function gameAccountLeaseCount (data) {
  return request({
    url: '/game/gameAccountLeaseCount',
    data
  })
}

export function getMostAccountGroup (data) {
  return request({
    url: '/game/getMostAccountGroup',
    data
  })
}
