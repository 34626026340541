import request from '@/utils/request'

export function queryModuleList (data) {
  return request({
    url: '/activity/old/groupList',
    data
  })
}

export function toggleGroupStatus (data) {
  return request({
    url: '/activity/old/groupStatus',
    data
  })
}

export function createOrSaveGroup (data) {
  return request({
    url: '/activity/old/groupSave',
    data
  })
}

export function queryAdvertisementList (data) {
  return request({
    url: '/activity/old/resource',
    data
  })
}

export function queryAdvertisementDetail (data) {
  return request({
    url: '/activity/old/resourceOne',
    data
  })
}

export function toggleAdvertisementStatus (data) {
  return request({
    url: '/activity/old/resourceStatus',
    data
  })
}

export function updateOrSaveAdvertisement (data) {
  return request({
    url: '/activity/old/resourceSave',
    data
  })
}

export function queryGroupDetail (data) {
  return request({
    url: '/activity/old/groupOne',
    data
  })
}
