<template>
  <div class="SkeletonIndex">
    <div class="content">
      <div class="header">
        <div class="left">
          <div class="input" />
          <div class="input" />
          <div class="button" />
        </div>
        <div class="right">
          <div class="button" />
        </div>
      </div>
      <div class="table">
        <div class="table-header" />
        <div
          v-for="item in 20"
          :key="item"
          class="table-row"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SkeletonIndex'
}
</script>

<style scoped lang="less">
  @color: #f5f5f5;
  @keyframes skeleton-loading {
    0% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0 50%;
    }
  }

  .background () {
    @start: 40%;
    background-image: linear-gradient(90deg, @color @start, #eeeeee 50%,  @color (100% - @start));
    background-size: 400% 100%;
    background-position: 100% 50%;
    background-attachment: fixed;
    animation: skeleton-loading 1.4s ease infinite;
  }

  .input{
    width: 217px;
    height: 36px;
    border-radius: 4px;
    margin-right: 10px;
    .background;
  }
  .button{
    width: 100px;
    height: 36px;
    border-radius: 4px;
    .background;
  }
  .SkeletonIndex{
    padding: 20px;
    box-sizing: border-box;
    width: 100%;
    position: absolute;
    z-index: 2000;
    .content{
      background: white;
      height: calc(100vh - 130px);
      overflow: hidden;
      box-sizing: border-box;
      padding: 14px;
      border-radius: 4px;
      box-shadow: 2px 2px 2px rgba(153,153,153, 0.2);
      .header{
        display: flex;
        justify-content: space-between;
        height: 60px;
        .left{
          display: flex;
        }
      }
      .table{
        margin-top: 20px;
        .table-header{
          width: 100%;
          height: 50px;
          .background;
        }
        .table-row{
          width: 100%;
          height: 60px;
          margin: 20px 0;
          .background;
        }
      }
    }
  }
</style>
