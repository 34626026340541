import { loginByUsername, getUserInfo } from '@/api/user'
import { setToken, removeToken, getToken } from '@/utils/auth'
import router from '@/router'
const state = {
  user: {},
  token: getToken()
}

const mutations = {
  SET_USERINFO (state, user) {
    state.user = user
  },
  SET_TOKEN (state, token) {
    state.token = token
  }
}

const actions = {
// 用户名登录
  loginByUsername ({ commit }, userInfo) {
    return loginByUsername(userInfo).then(response => {
      if (response.code === 200) {
        const data = response.data
        commit('SET_TOKEN', data.token)
        setToken(data.token)
      }
      return response
    }).catch(error => {
      return error
    })
  },

  // 获取用户信息
  GetUserInfo ({ commit, state }) {
    return getUserInfo({
      token: state.token
    }).then(response => {
      if (!response.data) {
        return new Error('Verification failed, please login again.')
      }
      commit('SET_USERINFO', response.data)
      return response.data
    }).catch(error => {
      return error
    })
  },
  // remove token
  resetToken ({ commit }) {
    commit('SET_TOKEN', '')
    removeToken()
  },
  logout ({ dispatch }) {
    dispatch('resetToken')
    router.push('/login')
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
