const enumFactor = (obj) => {
  const temp = { ...obj }
  for (const key in obj) {
    temp[obj[key]] = key
  }
  return temp
}

export const APPID = enumFactor({
  Netboom: 20,
  Bifrost: 12
})

export const GOODS_TYPE = {
  VIP: 2,
  GOLD: 7,
  bothVipAndGold: 0
}

export const CHANNEL = enumFactor({
  Android: 1,
  IOS: 2,
  H5: 4,
  WEB: 3
})

export const OS_PLATFORM = {
  ANDROID: 'ANDROID',
  IOS: 'IOS'
}

//  0: '基准组', 1: 'V1体验组', 2: 'V2体验组'
export const EXPERIMENT_GROUP = {
  BASE_GROUP: 0,
  V1_GROUP: 1,
  V2_GROUP: 2
}

export const DIVISOR = {
  // 真实金额除数 1000000
  TRUE_AMOUNT: 1000000
}

export const triggerModeIndex = {
  REGISTER_COIN: 0,
  FIRST_CHARGE_COIN: 1,
  FIRST_CHARGE_DISCOUNT: 2
}
export const triggerModeValue = {
  REGISTER_COIN: 1,
  FIRST_CHARGE_COIN: 2,
  FIRST_CHARGE_DISCOUNT: 3
}

export const StartMode = {
  NORMAL: -1,
  APPLICATION: 1,
  STEAM: 3,
  EPIC: 6,
  UPLAY: 7
}

// 用户分区
export const USER_AREA = {
  History: '1',
  // Europe: '1',
  // America: '2',
  // Arab: '3',
  // Asia: '4',
  Other: '0'
}

export const GAME_PLATFORM = {
  Steam: 3,
  Epic: 6,
  Uplay: 7
}

export const StateEnum = {
  Delist: '下架',
  Listing: '上架'
}

export const PaymentTypeEnum = {
  Recharge: 'recharge',
  Subscription: 'subscription'
}
