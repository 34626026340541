import Vue from 'vue'
import Vuex from 'vuex'
import app from '@/store/app'
import user from '@/store/user'
import tagsView from '@/store/tagsView'
import permission from '@/store/permission'
import gameList from '@/store/gameList'
import appManage from '@/store/appManage'
import rentAccount from '@/store/rentAccount'
import rechargeSystem from '@/store/rechargeSystem'
import selectOptions from '@/store/selectOptions'
Vue.use(Vuex)

const state = {}
const mutations = {}
const actions = {}
const modules = {
  app,
  user,
  tagsView,
  permission,
  gameList,
  appManage,
  rentAccount,
  rechargeSystem,
  selectOptions
}

export default new Vuex.Store({
  state,
  mutations,
  actions,
  modules
})
