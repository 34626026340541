import LayoutMain from '@/layouts/LayoutMain'
import {
  AppCategoryManagement
} from '@/router/asyncRoutes/_modules'

export const APP_ROUTES = {
  path: 'netboomVR',
  redirect: 'ModuleList',
  name: 'netboomVR',
  alwaysShow: true,
  meta: {
    title: 'netboomVR',
    icon: 'html5'
  },
  component: LayoutMain,
  children: [
    ...AppCategoryManagement,
    {
      path: '/netboomVR/AudtVersionConfig',
      name: '审核版本配置',
      meta: {
        title: '审核版本配置'
      },
      component: () => import('@/views/netboomVR/audtVersionConfig/index.vue')
    }
  ]
}
