import request from '@/utils/request'
export function loginByUsername (data) {
  return request({
    url: '/admin/adminUserLogin',
    data
  })
}
export function getUserInfo (data) {
  return request({
    url: '/admin/loginUserInfo',
    data
  })
}
// 封禁用户
export function userDisabled (data) {
  return request({
    url: '/user/userDisabled',
    data
  })
}
