import { render, staticRenderFns } from "./LayoutIndex.vue?vue&type=template&id=9c81862c&scoped=true"
import script from "./LayoutIndex.vue?vue&type=script&lang=js"
export * from "./LayoutIndex.vue?vue&type=script&lang=js"
import style0 from "./LayoutIndex.vue?vue&type=style&index=0&id=9c81862c&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9c81862c",
  null
  
)

export default component.exports