import { render, staticRenderFns } from "./SkeletonIndex.vue?vue&type=template&id=764c9225&scoped=true"
import script from "./SkeletonIndex.vue?vue&type=script&lang=js"
export * from "./SkeletonIndex.vue?vue&type=script&lang=js"
import style0 from "./SkeletonIndex.vue?vue&type=style&index=0&id=764c9225&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "764c9225",
  null
  
)

export default component.exports