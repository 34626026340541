<template>
  <div
    class="LayoutMain"
    :class="{
      isCollapse: isCollapse
    }"
  >
    <Sidebar />
    <div class="main-container">
      <Navbar />
      <TagsView />
      <LayoutMainRouterView />
    </div>
  </div>
</template>

<script>
import LayoutMainRouterView from '@/layouts/LayoutMainRouterView'
import Sidebar from '@/layouts/Sidebar'
import Navbar from '@/layouts/Navbar/index'
import TagsView from '@/layouts/TagsView'
import { mapState } from 'vuex'
export default {
  name: 'LayoutMain',
  components: {
    LayoutMainRouterView,
    Sidebar,
    Navbar,
    TagsView
  },
  computed: {
    ...mapState('app', ['isCollapse'])
  }
}
</script>

<style scoped lang="less">
  .LayoutMain {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    background: #F5F5F5;
    padding-left: @menu-width;
    box-sizing: border-box;
    transition: padding-left @menu-transition-times;
    &.isCollapse{
      padding-left: 54px;
    }
    .main-container{
      position: relative;
      width: 100%;
      overflow-y: auto;
      overflow-x: hidden;
      padding-top: 84px;
      box-sizing: border-box;
    }
  }
</style>
