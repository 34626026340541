import { APPID, CHANNEL, OS_PLATFORM } from '@/enum'

const state = {
  isCollapse: false,
  language: 'zh',
  permissionRoutes: [],
  appIdMap: {
    // int64 channel=16;//客户端(1:安卓  2:苹果)

    netboomAndroid: {
      // 接口使用的参数
      id: APPID.Netboom,
      channel: CHANNEL.Android,
      platform: OS_PLATFORM.ANDROID,
      theme: '#f4b600',

      // Dashboard及项目抉择下拉框的参数
      userCount: '--',
      icon: 'android',
      projectName: 'Netboom-Android',
      logo: require('@/assets/img/netboom-android.png'),
      project: 'netboomAndroid'
    },
    netboomH5: {
      id: APPID.Netboom,
      theme: '#f4b600',
      channel: CHANNEL.H5,
      userCount: '--',
      icon: 'html5',
      projectName: 'Netboom - H5',
      logo: require('@/assets/img/netboom-h5.png'),
      project: 'netboomH5'
    },
    // bifrostIos: {
    //   id: APPID.Netboom,
    //   theme: '#A60001',

    //   userCount: '--',
    //   icon: 'apple',
    //   projectName: 'Biforst-IOS',
    //   logo: require('@/assets/img/bifrost-ios.png'),
    //   project: 'bifrostIos'
    // },
    // bifrostAndroid: {
    //   id: APPID.Bifrost,
    //   platform: OS_PLATFORM.ANDROID,
    //   theme: '#8229e5',

    //   userCount: '--',
    //   icon: 'android',
    //   projectName: 'Bifrost-Android',
    //   logo: require('@/assets/img/bifrost-android.png'),
    //   project: 'bifrostAndroid'
    // },
    // netboomIos: {
    //   id: APPID.Netboom,
    //   channel: CHANNEL.IOS,
    //   platform: OS_PLATFORM.IOS,
    //   theme: '#A60001',

    //   userCount: '--',
    //   icon: 'apple',
    //   projectName: 'Netboom-IOS',
    //   logo: require('@/assets/img/bifrost-ios.png'),
    //   project: 'netboomIos'
    // },
    // netbangAndroid: {
    //   id: APPID.Netboom,
    //   channel: CHANNEL.Android,
    //   platform: OS_PLATFORM.ANDROID,
    //   // 包类型(0:正式包  n:第n版马甲包)
    //   pkgType: 1,
    //   theme: '#1ca0df',

    //   userCount: '--',
    //   icon: 'android',
    //   projectName: 'NetBang-Android',
    //   logo: require('@/assets/img/netboom-android.png'),
    //   project: 'netbangAndroid'
    // },
    // netboomAndroid2: {
    //   id: APPID.Netboom,
    //   channel: CHANNEL.Android,
    //   platform: OS_PLATFORM.ANDROID,
    //   // 包类型(0:正式包  n:第n版马甲包)
    //   pkgType: 2,
    //   theme: '#34b458',

    //   userCount: '--',
    //   icon: 'android',
    //   projectName: 'Netboom-Android2',
    //   logo: require('@/assets/img/netboom-android.png'),
    //   project: 'netboomAndroid2'
    // },
    // spanishAndroid: {
    //   id: APPID.Netboom,
    //   channel: CHANNEL.Android,
    //   platform: OS_PLATFORM.ANDROID,
    //   // 包类型(0:正式包  n:第n版马甲包)
    //   pkgType: 3,
    //   theme: '#34b458',

    //   userCount: '--',
    //   icon: 'android',
    //   projectName: 'Spanish-Android',
    //   logo: require('@/assets/img/netboom-android.png'),
    //   project: 'spanishAndroid'
    // },
    // arabicAndroid: {
    //   id: APPID.Netboom,
    //   channel: CHANNEL.Android,
    //   platform: OS_PLATFORM.ANDROID,
    //   // 包类型(0:正式包  n:第n版马甲包)
    //   pkgType: 4,
    //   theme: '#34b458',

    //   userCount: '--',
    //   icon: 'android',
    //   projectName: 'Arabic-Android',
    //   logo: require('@/assets/img/netboom-android.png'),
    //   project: 'arabicAndroid'
    // },
    // tfIos: {
    //   id: APPID.Netboom,
    //   channel: CHANNEL.IOS,
    //   platform: OS_PLATFORM.IOS,
    //   // 包类型(0:正式包  n:第n版马甲包)
    //   pkgType: 5,
    //   theme: '#0099CC',
    //   userCount: '--',
    //   icon: 'apple',
    //   projectName: 'TF IOS',
    //   logo: require('@/assets/img/netboom-android.png'),
    //   project: 'tfIos'
    // },
    netboomWeb: {
      id: APPID.Netboom,
      channel: CHANNEL.WEB,
      // platform: OS_PLATFORM.NetboomWeb,
      // 包类型(0:正式包  n:第n版马甲包)
      // pkgType: 5,
      theme: '#0099CC',
      userCount: '--',
      icon: 'html5',
      projectName: 'Netboom - Web',
      logo: require('@/assets/img/netboom-android.png'),
      project: 'netboomWeb'
    },
    FingertipsIOS: {
      id: APPID.Netboom,
      channel: 2,
      // platform: OS_PLATFORM.NetboomWeb,
      // 包类型(0:正式包  n:第n版马甲包)
      pkgType: 1,
      theme: '#0099CC',
      userCount: '--',
      icon: 'html5',
      projectName: 'Fingertips - IOS',
      logo: require('@/assets/img/bifrost-ios.png'),
      project: 'FingertipsIOS'
    },
    JoyarkGameIOS: {
      id: APPID.Netboom,
      channel: 2,
      // platform: OS_PLATFORM.NetboomWeb,
      // 包类型(0:正式包  n:第n版马甲包)
      pkgType: 2,
      theme: '#0099CC',
      userCount: '--',
      icon: 'html5',
      projectName: 'Netboom: Gaming',
      logo: require('@/assets/img/bifrost-ios.png'),
      project: 'JoyarkGameIOS'
    },
    HoloLinkIOS: {
      id: APPID.Netboom,
      channel: 2,
      // platform: OS_PLATFORM.NetboomWeb,
      // 包类型(0:正式包  n:第n版马甲包)
      pkgType: 3,
      theme: '#0099CC',
      userCount: '--',
      icon: 'html5',
      projectName: 'HoloLink - IOS',
      logo: require('@/assets/img/bifrost-ios.png'),
      project: 'HoloLinkIOS'
    }
    // netboomVR: {
    //   id: APPID.Netboom,
    //   channel: 5,
    //   pkgType: 0,
    //   theme: '#0099CC',
    //   userCount: '--',
    //   icon: 'html5',
    //   projectName: 'netboomVR',
    //   logo: require('@/assets/img/bifrost-ios.png'),
    //   project: 'netboomVR'
    // }
  },
  appId: '',
  channel: '',
  platform: '',
  appName: '',
  pkgType: 0,
  oldTheme: '#8229e5',
  theme: '#8229e5'
}

const getters = {
  projectListForDashboard (state) {
    return Object.keys(state.appIdMap).map(ProjectName => {
      const { userCount, icon, projectName, logo, project } = state.appIdMap[
        ProjectName
      ]
      return {
        userCount,
        icon,
        projectName,
        logo,
        project
      }
    })
  },
  projectListForSelectOption (state) {
    return Object.keys(state.appIdMap).map(ProjectName => {
      const { icon, projectName, project } = state.appIdMap[ProjectName]
      return {
        icon,
        projectName,
        project
      }
    })
  },
  projectListForNavIcon (state) {
    return Object.keys(state.appIdMap).reduce(
      (result, ProjectName) => {
        const { logo, projectName: name } = state.appIdMap[ProjectName]
        result[ProjectName] = { logo, name }
        return result
      },
      {
        unknown: {
          logo: require('@/assets/img/logo.png'),
          name: 'Unknown'
        },
        home: {
          logo: require('@/assets/img/logo.png'),
          name: 'Home'
        }
      }
    )
  }
}
const mutations = {
  updateIsCollapse (state) {
    state.isCollapse = !state.isCollapse
  },
  updateAppIdByName (state, appName) {
    state.appId = (state.appIdMap[appName] || {}).id || ''
    state.channel = (state.appIdMap[appName] || {}).channel || ''
    state.platform = (state.appIdMap[appName] || {}).platform || ''
    state.appName = appName
    state.oldTheme = state.theme
    state.theme = (state.appIdMap[appName] || {}).theme || '#8229e5'
    state.pkgType = (state.appIdMap[appName] || {}).pkgType || 0
  },
  updateTheme (state, theme) {
    state.oldTheme = state.theme
    state.theme = theme
  }
}
const actions = {}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
