import LayoutMain from '@/layouts/LayoutMain'
import LayoutIndex from '@/layouts/LayoutIndex'
import {
  APP_ROUTES
} from '@/router/asyncRoutes/FingertipsIOS/routesData'

export default [
  {
    path: 'FingertipsIOS',
    alias: '/',
    component: LayoutIndex,
    redirect: '/FingertipsIOS/dashboard',
    meta: {
      title: 'FingertipsIOS',
      isLayout: true
    },
    children: [
      {
        path: '/FingertipsIOS/dashboard',
        component: LayoutMain,
        children: [
          {
            path: '/FingertipsIOS/dashboard',
            name: 'Dashboard',
            meta: {
              title: 'Dashboard',
              icon: 'dashboard'
            },
            component: () => import('@/views/TODO')
          }
        ]
      },
      APP_ROUTES,
      {
        path: 'news',
        name: 'news',
        alwaysShow: true,
        meta: {
          title: 'news',
          icon: 'dashboard'
        },
        component: LayoutMain,
        children: [
          {
            path: 'news',
            component: () => import('@/views/FingertipsiOS/news/index.vue'),
            name: 'news',
            meta: {
              title: 'news'
            }
          },
          {
            path: 'editNews',
            component: () => import('@/views/FingertipsiOS/news/editNews.vue'),
            name: 'editNews',
            hidden: true,
            meta: {
              title: 'editNews'
            }
          }
        ]
      },
      {
        path: 'examine-switch-manage',
        component: LayoutMain,
        redirect: 'examine-manage',
        alwaysShow: true,
        meta: {
          title: '主包审核管理',
          icon: 'address-book'
        },
        children: [
          {
            path: 'examine-manage',
            component: () => import('@/views/_NETBOOM_IOS/ExamineManage.vue'),
            name: '审核开关',
            meta: {
              title: '审核开关'
            }
          },
          {
            path: 'index',
            component: () => import('@/views/blacklist/BlackWhiteListIOS'),
            name: '客户端版本',
            meta: {
              title: '客户端版本',
              noCache: true
            }
          }
        ]
      }
    ]
  }
]
